import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export const Circle: FC<{ fill?: string }> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="32" viewBox="0 0 37 37" width="32" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="18.5" fill={fill} r="17.5" stroke="black" strokeWidth="2" />
    </svg>
  );
});

import { Box } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';

export interface PlusMinusButtonProps {
  children: ReactNode;
  onClick: () => void;
}

export const PlusMinusButton: FC<PlusMinusButtonProps> = memo(({ children, onClick }) => {
  return (
    <Button as="button" onClick={onClick} size="sm" type="button" variant="beige">
      <Box w={{ base: '20px', md: '14px' }}>{children}</Box>
    </Button>
  );
});

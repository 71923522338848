import type { FC } from 'react';
import { memo } from 'react';

export const MouseCursor: FC = memo(() => {
  return (
    <svg fill="none" height="34" viewBox="0 0 34 34" width="34" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 29.5L2 2L30 11L19.5 15L32 27.5L27.5 32L15 19L11 29.5Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

export const WhiteStarBurst: FC = memo(() => {
  return (
    <svg fill="none" height="80" viewBox="0 0 64 80" width="64" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.19594 39.7536L1.19619 39.7535C13.2477 37.7979 20.4218 35.375 24.9417 29.4866C27.1865 26.5621 28.724 22.8513 29.9371 18.0851C30.7233 14.9957 31.38 11.4343 32.0005 7.31838C32.6214 11.4343 33.2783 14.9956 34.0648 18.0848C35.278 22.8509 36.8154 26.5614 39.0599 29.4857C43.5793 35.3739 50.7523 37.7968 62.8037 39.7535L62.8041 39.7536C62.8994 39.769 62.9318 39.8031 62.9486 39.8249C62.9744 39.8583 63 39.9192 63 40C63 40.0808 62.9744 40.1416 62.9486 40.1751C62.9318 40.1969 62.8994 40.231 62.8041 40.2464L62.8038 40.2465C50.7523 42.2021 43.5782 44.625 39.0583 50.5134C36.8135 53.4379 35.276 57.1487 34.0629 61.9149C33.2766 65.0043 32.62 68.5657 31.9995 72.6816C31.3786 68.5657 30.7217 65.0044 29.9352 61.9152C28.722 57.1491 27.1846 53.4386 24.9401 50.5143C20.4207 44.6261 13.2477 42.2032 1.19628 40.2465L1.19593 40.2464C1.10057 40.231 1.06818 40.1969 1.0514 40.1751C1.02563 40.1416 1 40.0808 1 40C1 39.9192 1.02563 39.8583 1.0514 39.8249C1.06818 39.8031 1.10057 39.769 1.19594 39.7536Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

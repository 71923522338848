import { sessionsToListItem } from '@ours/utils';
import { useMemo } from 'react';

import { useArrayToMap } from '../../../hooks/useArrayToMap';
import { useToArray } from '../../../hooks/useToArray';
import { useDashboardContext } from '../DashboardContext';

export const useDashboardListItems = () => {
  const { data } = useDashboardContext();
  const membershipId = data?.id || '';
  const sessions = useToArray(data?.sessions);
  const scheduledEventsBySessionId = useArrayToMap('sessionId', data?.scheduledEvents);
  const sessionProgresses = useArrayToMap('sessionId', data?.sessionProgresses);

  return useMemo(() => {
    return sessionsToListItem(
      sessions.map((s) => ({
        id: s.id,
        sessionDescription: s.sessionDescription || '',
        signedCardDeckUrl: s.resolvedValues?.cardDeckSignedUrl || '',
        title: s.title || '',
        type: s.type,
      })),
      scheduledEventsBySessionId,
      sessionProgresses,
      membershipId
    );
  }, [sessions, scheduledEventsBySessionId, sessionProgresses, membershipId]);
};

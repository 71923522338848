import { Box } from '@chakra-ui/react';
import { COMPANY_MAIL_TO_LINK } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { UnderlinedLink } from '../../../components/Links/UnderlinedLink';

interface Props {}

export const EmptyBodyCTA: FC<Props> = memo(() => {
  return (
    <Box>
      Already have a plan and not seeing it here?{' '}
      <UnderlinedLink as="a" fontWeight="600" href={COMPANY_MAIL_TO_LINK} target="_blank">
        Contact us
      </UnderlinedLink>{' '}
      for support.
    </Box>
  );
});

import type { BoxProps } from '@chakra-ui/react';
import { Box, Modal, ModalContent, ModalOverlay, useBoolean } from '@chakra-ui/react';
import { RoundedCorners } from '@ours/utils';
import { CameraIcon, MouseCursor } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';
import { TbRefresh } from 'react-icons/tb';

import { Button } from '../../components/Buttons/Button';
import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useEvent } from '../../hooks/useEvent';
import { useRandomItem } from '../../hooks/useRandomItem';
import { DashboardCTABlock } from '../DashboardContainer/lib/DashboardCTABlock';
import { Discussion } from '../Discussion/Discussion';
import { Drawing } from '../Drawing/Drawing';
import { Journal } from '../Journal/Journal';
import { PhotoBooth } from '../PhotoBooth/PhotoBooth';
import { QuizMatching } from '../QuizMatching/QuizMatching';

import { DiscussionSvg } from './lib/DiscussionSvg';
import { JournalSvg } from './lib/JournalSvg';

type QuizSchema = { kind: 'quiz'; pairs: [string, string][] };
type DrawingSchema = { kind: 'drawing'; prompt: string };
type PhotoBoothSchema = { kind: 'photoBooth'; numberOfImg: 2 | 4; prompts: string | string[] };
type JournalSchema = { kind: 'journal'; promptMarkdown?: string; promptTitle: string };
type DiscussionSchema = { digDeeperPrompt?: string; kind: 'discussion'; prompt: string };
type DashboardActivity =
  | QuizSchema
  | PhotoBoothSchema
  | DrawingSchema
  | JournalSchema
  | DiscussionSchema;

export const dashboardActivityOptions: DashboardActivity[] = [
  {
    kind: 'quiz',
    pairs: [
      ['Space Needle', 'Washington'],
      ['Liberty Bell', 'Pennsylvania'],
      ['Yosemite', 'California'],
      ['Grand Canyon', 'Arizona'],
    ],
  },
  {
    kind: 'quiz',
    pairs: [
      ['Chrissy Teigen', 'John Legend'],
      ['Kristen Bell', 'Dax Shepard'],
      ['Blake Lively', 'Ryan Reynolds'],
      ['Neil Patrick Harris', 'David Burtka'],
    ],
  },
  { kind: 'drawing', prompt: 'Draw your favorite memory together' },
  { kind: 'drawing', prompt: 'Draw yourselves in your happy place' },
  { kind: 'drawing', prompt: 'Draw your dream vacation together' },
  { kind: 'drawing', prompt: 'Draw 4 shapes describing your relationship' },
  { kind: 'drawing', prompt: 'Draw your ideal evening together' },
  { kind: 'drawing', prompt: 'Work together to draw a butterfly' },
  { kind: 'photoBooth', numberOfImg: 2, prompts: 'Show off your best dance moves' },
  { kind: 'photoBooth', numberOfImg: 2, prompts: 'Show off your goofiest faces' },
  {
    kind: 'photoBooth',
    numberOfImg: 2,
    prompts: 'Mime your favorite snacks for accompanying each session',
  },
  {
    kind: 'photoBooth',
    numberOfImg: 4,
    prompts: [
      'Show the emotion you felt the first time you met your partner',
      'Do something to make your partner laugh',
      'Pretend to sneeze',
      'Show the emotion you feel for your partner right now',
    ],
  },
  {
    kind: 'journal',
    promptTitle: 'What are you grateful for in your relationship?',
  },
  {
    kind: 'journal',
    promptTitle: 'List 3 things that are special about your partner and why',
  },
  {
    kind: 'journal',
    promptTitle: 'Type up the story of your favorite memory with your partner',
  },
  {
    kind: 'journal',
    promptTitle: 'Come up with 10 things that make you laugh and write them down',
  },
  {
    kind: 'journal',
    promptTitle: 'Describe the first time you realized you were in love with your partner',
  },
  {
    kind: 'journal',
    promptTitle:
      'List your top 5 relationship moments and then pick 1 and write about why it was a top moment for you',
  },
  {
    kind: 'journal',
    promptTitle:
      'Describe a time your partner caught you off guard or surprised you. How did you react?',
  },
  {
    kind: 'journal',
    promptTitle: 'What do you most appreciate about your relationship?',
  },
  {
    kind: 'journal',
    promptTitle: "Write about your favorite place and why it's your favorite?",
  },
  {
    kind: 'journal',
    promptTitle:
      'Imagine yourself in 5 years, what does it look like? who do you see? what are you most excited for? what are you most worried about?',
  },
  {
    digDeeperPrompt: 'List the top way your partners shown you love',
    kind: 'discussion',
    prompt: 'Discuss how you each like to give and receive love',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss what respect looks like in a relationship',
  },
  {
    kind: 'discussion',
    prompt:
      'Scenario: You just won the lottery, talk about what you would do with your shared winnings',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss - is love at first sight real?',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss what 1 thing you could commit to doing to improve your relationship this week',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss what 1 thing you would want to have a natural talent in and why?',
  },
  {
    kind: 'discussion',
    prompt: 'Name three things that make you proud of your partner',
  },
  {
    kind: 'discussion',
    prompt: 'When you think about the next year, what are you most excited about?',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss where you want to + what you want to do for your next date night',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss how your relationship has changed since you first started dating',
  },
  {
    kind: 'discussion',
    prompt: 'Discuss your happiest moment in the last week',
  },
];

type DashboardProps = {
  Comp: FC<any>;
  Icon: FC;
  compProps?: Record<string, string>;
  corners: BoxProps;
  cta: string;
  subtitle: string;
  title: string;
  type: string;
};
const dashboardProps: Record<DashboardActivity['kind'], DashboardProps> = {
  discussion: {
    Comp: Discussion,
    Icon: DiscussionSvg,
    corners: { borderBottomLeftRadius: '800', borderBottomRightRadius: '800' },
    cta: "Let's Go!",
    subtitle: 'Warm up or cool down with one of the discussion prompts from the Guides at OURS.',
    title: 'Discussion',
    type: 'DISCUSSION_DASHBOARD_OPEN',
  },
  drawing: {
    Comp: Drawing,
    Icon: MouseCursor,
    compProps: { maxW: 'full' },
    corners: { borderBottomLeftRadius: '800', borderTopRightRadius: '800' },
    cta: "Let's Go!",
    subtitle: 'Warm up or cool down with one of the drawing prompts from the Guides at OURS.',
    title: 'Drawing',
    type: 'DRAWING_DASHBOARD_OPEN',
  },
  journal: {
    Comp: Journal,
    Icon: JournalSvg,
    corners: { borderBottomLeftRadius: '800', borderBottomRightRadius: '800' },
    cta: "Let's Go!",
    subtitle: 'Warm up or cool down with one of the journal prompts from the Guides at OURS.',
    title: 'Journal',
    type: 'JOURNAL_DASHBOARD_OPEN',
  },
  photoBooth: {
    Comp: PhotoBooth,
    Icon: CameraIcon,
    corners: RoundedCorners.RotatingLg,
    cta: "Let's Go!",
    subtitle:
      'Enter into the photo booth to get prompts and remember your OURS experience as a couple!',
    title: 'Photo Booth',
    type: 'PHOTOBOOTH_DASHBOARD_OPEN',
  },
  quiz: {
    Comp: QuizMatching,
    Icon: DiscussionSvg,
    corners: { borderBottomLeftRadius: '800', borderBottomRightRadius: '800' },
    cta: "Let's Go!",
    subtitle: 'Take a fun quiz to warm up before jumping into a session.',
    title: 'Quiz',
    type: 'QUIZ_DASHBOARD_START',
  },
};

export const ActivityCenter: FC = memo(() => {
  const [isOpen, { off, on }] = useBoolean(false);
  const [activity, getNext] = useRandomItem(dashboardActivityOptions);
  const { Comp, Icon, compProps, corners, cta, subtitle, title, type } =
    dashboardProps[activity?.kind || 'drawing'];
  const { trackEvent } = useAnalyticsEvent();
  const description =
    activity?.kind === 'journal'
      ? activity.promptTitle
      : activity?.kind === 'discussion'
      ? activity.prompt
      : subtitle;

  const onClose = useEvent(() => {
    getNext();
    off();
  });

  const onOpen = useEvent(() => {
    on();
    trackEvent({ type: type as any });
  });

  return (
    <>
      <DashboardCTABlock
        Button={() => (
          <Button as="button" onClick={onOpen} type="button" w="full">
            {cta}
          </Button>
        )}
        Child={() => (
          <Box pos="absolute" right="10px" top="10px">
            <Box as="button" onClick={getNext}>
              <TbRefresh />
            </Box>
          </Box>
        )}
        Icon={Icon}
        description={description}
        title={title}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent {...corners}>
          <Box>
            <Comp {...compProps} {...activity} />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

import { useInvitePartnerToMembershipMutation } from '../../generated/apollo-hooks';
import { useEvent } from '../../hooks/useEvent';
import { useToArray } from '../../hooks/useToArray';
import { useDashboardContext } from '../DashboardContainer/DashboardContext';

import { YourPartner } from './YourPartner';

interface Props {}

export const YourPartnerContainer: FC<Props> = memo(() => {
  const { data, refetch } = useDashboardContext();
  const [invite] = useInvitePartnerToMembershipMutation();
  const membershipId = data?.id || '';

  const onInvite = useEvent(async (email: string) => {
    await invite({
      variables: { membershipId, partnerEmail: email },
    });
    await refetch();
  });
  const canInvitePartner = !!data?.resolvedValues?.canInvitePartner;
  const partnersName = data?.partnerUser?.fullName || '';
  const partnersEmail = data?.partnerUser?.email || '';
  const studentIds = useToArray(data?.studentIds);
  const hasInvitedPartner = studentIds.length > 1;

  return (
    <YourPartner
      canInvitePartner={canInvitePartner}
      hasInvitedPartner={hasInvitedPartner}
      onInvite={onInvite}
      partnersEmail={partnersEmail}
      partnersName={partnersName}
    />
  );
});

import { isEqual, timeRelativity } from '@ours/utils';
import type { Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';

import { useInterval } from '../useInterval';

export const useTimeRelativity = (
  dateOrTime: string | number | Date | Dayjs | undefined | null
) => {
  const [response, setResponse] = useState({} as Response);

  const rerender = useCallback(() => {
    const nextResponse = timeRelativity(dateOrTime);
    if (!isEqual(nextResponse, response)) {
      setResponse(nextResponse);
    }
  }, [response, dateOrTime]);

  useInterval(rerender, 1000, true);

  return response;
};

export interface Response {
  isAfterWithinHr: boolean;
  isBeforeWithin24Hr: boolean;
  isFuture: boolean;
  isPast: boolean;
}

import { useBoolean } from '@chakra-ui/react';
import type { SessionListItemProps } from '@ours/utils';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { RainbowText } from '../../../components/Rainbows/RainbowText';
import { useTimeRelativity } from '../../../hooks/date/useTimeRelativity';
import { useEvent } from '../../../hooks/useEvent';
import { MembershipSessionScheduler } from '../../MembershipSessionScheduler/MembershipSessionScheduler';
import { MINI_ROADMAP_CONTENT } from '../miniRoadmapContent';
import type { SchedulingCB } from '../types';

export const LiveButton: FC<SessionListItemProps & SchedulingCB> = memo(
  ({ canScheduleLiveSession, isComplete, joinUrl, membershipId, sessionId, startTime }) => {
    const [open, { off, on }] = useBoolean(false);
    const onJoin = useCallback(() => {
      window.open(joinUrl || '');
    }, [joinUrl]);
    const { isAfterWithinHr } = useTimeRelativity(startTime);
    const onClickSchedule = useEvent(() => {
      on();
    });

    if (isComplete) {
      return <RainbowText isBold value={MINI_ROADMAP_CONTENT.COMPLETED_LIVE_SESSION} />;
    }

    if (canScheduleLiveSession) {
      return (
        <>
          <MembershipSessionScheduler
            isOpen={open}
            isThrottled
            membershipId={membershipId}
            onClose={off}
            sessionId={sessionId}
          />
          <Button
            as="button"
            onClick={onClickSchedule}
            size="rightRounded"
            type="button"
            variant="orange"
          >
            {MINI_ROADMAP_CONTENT.SCHEDULE}
          </Button>
        </>
      );
    }

    return joinUrl ? (
      <Button
        as="button"
        isDisabled={!isAfterWithinHr}
        onClick={onJoin}
        size="rightRounded"
        type="button"
      >
        {MINI_ROADMAP_CONTENT.JOIN}
      </Button>
    ) : null;
  }
);

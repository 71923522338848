import { Box, Center } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { WhiteStarBurst } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { useModal } from '../../hooks/useModal';
import { InvitePartnerModal } from '../InvitePartnerModal/InvitePartnerModal';

import { InviteYourPartner } from './lib/InviteYourPartner';
import { YourPartnerBox } from './lib/YourPartnerBox';

export interface Props {
  canInvitePartner: boolean;
  hasInvitedPartner: boolean;
  onInvite: (email: string) => Promise<void>;
  partnersEmail: string;
  partnersName: string;
}

export const YourPartner: FC<Props> = memo(
  ({ canInvitePartner, hasInvitedPartner, onInvite, partnersEmail, partnersName }) => {
    const { isOpen } = useModal('invitePartnerModal');

    return (
      <Center
        bg="brand.pink"
        border={Border}
        borderBottomLeftRadius="800"
        borderTopRightRadius="800"
        flexDir="column"
        pos="relative"
        py="500"
        textAlign="center"
      >
        <Box
          display={{ base: 'none', md: 'block' }}
          pos="absolute"
          right="30px"
          sx={{ svg: { w: '48px' } }}
          top="60px"
        >
          <WhiteStarBurst />
        </Box>
        {!hasInvitedPartner ? (
          <InviteYourPartner />
        ) : (
          <YourPartnerBox
            canInvitePartner={canInvitePartner}
            partnersEmail={partnersEmail}
            partnersName={partnersName}
          />
        )}
        {isOpen ? <InvitePartnerModal onInvite={onInvite} /> : null}
      </Center>
    );
  }
);

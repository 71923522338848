/* eslint-disable max-lines-per-function */
import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const FullHeroSvg: FC<Props> = memo(() => {
  return (
    <svg
      fill="none"
      height="275"
      viewBox="0 0 540 275"
      width="540"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M199.106 225.491V186.914H151.52V233.066C151.522 243.631 155.721 253.763 163.194 261.233C170.666 268.703 180.799 272.899 191.364 272.898H267.459V225.491H199.106Z"
        fill="#69A1F2"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M260.989 122.758L261.035 186.428H229.455L229.418 138.17C229.418 134.08 227.793 130.158 224.901 127.266C222.01 124.375 218.087 122.75 213.998 122.75C209.908 122.75 205.986 124.375 203.094 127.266C200.202 130.158 198.578 134.08 198.578 138.17V178.022C198.578 203.722 219.134 225.026 244.834 225.217C250.986 225.267 257.088 224.098 262.786 221.778C268.485 219.458 273.668 216.032 278.036 211.698C282.404 207.365 285.87 202.209 288.235 196.529C290.601 190.849 291.818 184.757 291.816 178.604V122.758H260.989Z"
        fill="#FF7760"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M329.006 211.5C320.936 211.497 312.945 213.084 305.489 216.17C298.033 219.257 291.258 223.781 285.551 229.486C279.844 235.191 275.317 241.965 272.229 249.42C269.14 256.875 267.55 264.865 267.55 272.935H390.478C390.475 264.867 388.883 256.879 385.793 249.426C382.703 241.974 378.176 235.203 372.469 229.5C366.763 223.797 359.988 219.274 352.534 216.189C345.079 213.104 337.09 211.518 329.022 211.521"
        fill="#FFA6E3"
      />
      <path
        d="M329.006 211.5C320.936 211.497 312.945 213.084 305.489 216.17C298.033 219.257 291.258 223.781 285.551 229.486C279.844 235.191 275.317 241.965 272.229 249.42C269.14 256.875 267.55 264.865 267.55 272.935H390.478C390.475 264.867 388.883 256.879 385.793 249.426C382.703 241.974 378.176 235.203 372.469 229.5C366.763 223.797 359.988 219.274 352.534 216.189C345.079 213.104 337.09 211.518 329.022 211.521"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M385.171 161.056V122.77H316.897C313.615 122.769 310.364 123.415 307.332 124.67C304.299 125.926 301.544 127.767 299.223 130.087C296.901 132.408 295.06 135.162 293.803 138.195C292.547 141.227 291.9 144.477 291.899 147.759C291.899 151.041 292.546 154.291 293.802 157.324C295.059 160.356 296.9 163.111 299.221 165.432C301.543 167.753 304.298 169.593 307.331 170.848C310.364 172.104 313.614 172.749 316.897 172.748H330.567V211.018H398.841C405.469 211.018 411.825 208.385 416.512 203.698C421.198 199.012 423.831 192.656 423.831 186.028C423.831 179.401 421.198 173.045 416.512 168.358C411.825 163.672 405.469 161.039 398.841 161.039L385.171 161.056Z"
        fill="#69A1F2"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M1 197.813C0.998922 182.967 5.40003 168.455 13.6467 156.112C21.8935 143.768 33.6154 134.147 47.3302 128.465C61.045 122.783 76.1366 121.296 90.6966 124.192C105.257 127.087 118.631 134.235 129.129 144.732C139.626 155.229 146.775 168.602 149.672 183.162C152.568 197.722 151.082 212.814 145.401 226.529C139.721 240.244 130.1 251.967 117.757 260.214C105.414 268.462 90.9022 272.864 76.0571 272.864C56.1516 272.864 37.0614 264.957 22.9856 250.882C8.90986 236.808 1.00145 217.718 1 197.813Z"
        fill="#FFAB00"
      />
      <path
        d="M1 197.813C0.998922 182.967 5.40003 168.455 13.6467 156.112C21.8935 143.768 33.6154 134.147 47.3302 128.465C61.045 122.783 76.1366 121.296 90.6966 124.192C105.257 127.087 118.631 134.235 129.129 144.732C139.626 155.229 146.775 168.602 149.672 183.162C152.568 197.722 151.082 212.814 145.401 226.529C139.721 240.244 130.1 251.967 117.757 260.214C105.414 268.462 90.9022 272.864 76.0571 272.864C56.1516 272.864 37.0614 264.957 22.9856 250.882C8.90986 236.808 1.00145 217.718 1 197.813"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M538.005 273.153H446.044C426.973 273.158 408.681 266.635 395.191 255.017C381.701 243.399 374.118 227.639 374.11 211.203H466.071C485.142 211.198 503.434 217.721 516.924 229.339C530.414 240.957 537.997 256.717 538.005 273.153Z"
        fill="#FF7760"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M424.049 211.119H500.577V45.3519C500.577 33.589 496.546 22.308 489.371 13.9904C482.196 5.67277 472.464 1 462.317 1C452.17 1 442.438 5.67277 435.263 13.9904C428.088 22.308 424.057 33.589 424.057 45.3519L424.049 211.119Z"
        fill="#FFAB00"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

export interface SessionStoreIconProps {}

export const SessionStoreIcon: FC<SessionStoreIconProps> = memo(() => {
  return (
    <svg fill="none" height="31" viewBox="0 0 42 31" width="42" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M16.9014 0.884277H17.7802H35.3228V2.64185H18.6589V10.8053H26.1701H26.3459L26.5083 10.873L31.5616 12.9798L30.8876 12.3053L29.3884 10.8053H31.5091H35.3228V12.5629H33.6299L35.9129 14.8473C36.6001 15.5348 35.8411 16.6681 34.9436 16.2939L25.9942 12.5629H17.7802H16.9014V11.6841V1.76307V0.884277ZM39.7827 6.72359C39.7827 4.52905 37.8478 2.64185 35.3224 2.64185V0.884277C38.6945 0.884277 41.5403 3.43889 41.5403 6.72359C41.5403 10.0083 38.6945 12.5629 35.3224 12.5629V10.8053C37.8478 10.8053 39.7827 8.91813 39.7827 6.72359Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M25.5234 14.6213H24.6446H7.10205V16.3789H23.7659V24.5424H16.2547H16.0789L15.9166 24.6101L10.8632 26.7168L11.5372 26.0424L13.0364 24.5424H10.9157H7.10205V26.3H8.79494L6.51187 28.5843C5.82474 29.2719 6.58368 30.4052 7.4812 30.031L16.4306 26.3H24.6446H25.5234V25.4212V15.5001V14.6213ZM2.6421 20.4607C2.6421 18.2661 4.57705 16.3789 7.10236 16.3789V14.6213C3.73032 14.6213 0.884521 17.1759 0.884521 20.4607C0.884521 23.7454 3.73032 26.3 7.10236 26.3V24.5424C4.57705 24.5424 2.6421 22.6552 2.6421 20.4607Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

export interface RedoProps {}

export const Redo: FC<RedoProps> = memo(() => {
  return (
    <svg fill="none" height="19" viewBox="0 0 20 19" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.05172 17.1034C4.60488 17.1034 1 13.4986 1 9.05172C1 4.60488 4.60488 1 9.05172 1"
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M8.43241 1.00003C12.8793 1.00003 16.4841 4.60491 16.4841 9.05176"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M12.7678 7.19356L15.7609 8.69012C16.1827 8.90099 16.6944 8.78859 16.989 8.42038L18.9615 5.95483"
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
});

import { Box, Flex } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { FullHeroSvg } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {
  Body: FC;
  BodyCTA: FC | null;
  CTAFooter: FC | null;
  subtitle: string;
  title: string;
}

export const DashboardHeroFull: FC<Props> = memo(
  ({ Body, BodyCTA, CTAFooter, subtitle, title }) => {
    return (
      <Flex justifyContent="center">
        <Flex>
          <Box
            bg="brand.orange"
            border={Border}
            borderTopRadius="800"
            px={{ base: '500', lg: '600', md: '400' }}
            py={{ base: '300', md: '500' }}
          >
            <Flex flexDir="column" gap={{ base: '200' }} w={{ base: 'full', lg: '66%' }}>
              <Box>
                <Box pb="100" textStyle="xl">
                  {title}
                </Box>
                <Box fontWeight="600" textStyle={'xs'}>
                  {subtitle}
                </Box>
              </Box>
              <Box textStyle="xs">
                <Body />
              </Box>
              <Box textStyle="xs">{BodyCTA ? <BodyCTA /> : null}</Box>
              <Box>{CTAFooter ? <CTAFooter /> : null}</Box>
            </Flex>
          </Box>
          <Flex
            alignItems="end"
            display={{ base: 'none', md: 'flex' }}
            mb="-1px"
            ml="-75px"
            sx={{ svg: { maxW: 'none' } }}
          >
            <FullHeroSvg />
          </Flex>
        </Flex>
      </Flex>
    );
  }
);

import type { FC } from 'react';

import { Cloud } from './Cloud';
import { Night } from './Night';
import { Rain } from './Rain';
import { Snow } from './Snow';
import { Sun } from './Sun';

type WeatherType = 'cloud' | 'night' | 'rain' | 'snow' | 'sun';
export const WeatherIconMap: Record<WeatherType, FC> = {
  cloud: Cloud,
  night: Night,
  rain: Rain,
  snow: Snow,
  sun: Sun,
};

export const DASHBOARD_PARTNER = {
  HEADING_NO_PARTNER: 'Your Partner',
  INVITE_PARTNER_CTA: 'Invite',
  INVITE_PARTNER_DESCRIPTION: `Enter your partner’s email so they can join OURS. We’ll give them a nudge with an email,
  but you can also remind them to check that inbox ;). Once they join, you can get started!`,
  INVITE_PARTNER_LABEL: 'Your Partner’s Email',
  INVITE_PARTNER_PLACEHOLDER: 'Enter here',
  INVITE_PARTNER_TEXT: 'Invite your partner',
  INVITE_SENT_TO: 'Invite sent to',
  NO_PARTNER_CTA: 'Invite your partner',
  REINVITE_PARTNER_CTA: 'Resend',
  REINVITE_PARTNER_DESCRIPTION: `Confirm your partner’s email below. After resending, if your partner still doesn’t receive an invite please contact us for support. (But don’t forget to check spam!)`,
  REINVITE_PARTNER_TEXT: 'Resend Invite',
  RESEND_INVITE: 'Resend Invite',
  SUB_HEADING_NO_PARTNER: 'OURS is just mine without your partner',
  SUCCESS_CTA: 'Oh, We’re Ready!',
  SUCCESS_HEADER: 'Get ready to grow old together.',
  SUCCESS_SUBHEADER: 'If your partner doesn’t receive an invite in 5 minutes,',
  YOUR_PARTNER_IS: 'Your Partner Is',
};

import type { FC } from 'react';
import { memo } from 'react';

export interface JournalSvgProps {}

export const JournalSvg: FC<JournalSvgProps> = memo(() => {
  return (
    <svg fill="none" height="32" viewBox="0 0 27 32" width="27" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 1H26V27C26 29.2091 24.2091 31 22 31H5C2.79086 31 1 29.2091 1 27V5C1 2.79086 2.79086 1 5 1Z"
        stroke="black"
        strokeWidth="2"
      />
      <line
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
        x1="10.5807"
        x2="17.2904"
        y1="6.78369"
        y2="6.78369"
      />
      <line
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
        x1="7.96777"
        x2="22.5162"
        y1="12.8379"
        y2="12.8379"
      />
      <line
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
        x1="7.96777"
        x2="22.5162"
        y1="18.8918"
        y2="18.8918"
      />
      <line
        stroke="black"
        strokeLinecap="round"
        strokeWidth="2"
        x1="7.96777"
        x2="22.5162"
        y1="24.946"
        y2="24.946"
      />
      <ellipse cx="4.35485" cy="12.973" fill="black" rx="0.870968" ry="0.864865" />
      <ellipse cx="4.35485" cy="12.973" fill="black" rx="0.870968" ry="0.864865" />
      <ellipse cx="4.35485" cy="19.027" fill="black" rx="0.870968" ry="0.864865" />
      <ellipse cx="4.35485" cy="19.027" fill="black" rx="0.870968" ry="0.864865" />
      <ellipse cx="4.35485" cy="25.0812" fill="black" rx="0.870968" ry="0.864865" />
      <ellipse cx="4.35485" cy="25.0812" fill="black" rx="0.870968" ry="0.864865" />
    </svg>
  );
});

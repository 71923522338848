import type { FC } from 'react';
import { memo } from 'react';

export interface DiscussionSvgProps {}

export const DiscussionSvg: FC<DiscussionSvgProps> = memo(() => {
  return (
    <svg fill="none" height="35" viewBox="0 0 48 35" width="48" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M19.0944 0.868408H20.0944H40.0566V2.86841H21.0944V12.1579H29.6415H29.8416L30.0263 12.2349L35.7767 14.6322L35.0097 13.8648L33.3038 12.1579H35.717H40.0566V14.1579H38.1302L40.7282 16.7573C41.5103 17.5399 40.6463 18.8292 39.6252 18.4035L29.4414 14.1579H20.0944H19.0944V13.1579V1.86841V0.868408ZM45.1321 7.51314C45.1321 5.01591 42.9303 2.86841 40.0566 2.86841V0.868408C43.8938 0.868408 47.1321 3.77538 47.1321 7.51314C47.1321 11.2509 43.8938 14.1579 40.0566 14.1579V12.1579C42.9303 12.1579 45.1321 10.0104 45.1321 7.51314Z"
        fill="black"
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M28.9056 16.5H27.9056H7.94337V18.5H26.9056V27.7895H18.3585H18.1584L17.9737 27.8665L12.2233 30.2638L12.9903 29.4964L14.6962 27.7895H12.283H7.94337V29.7895H9.86976L7.27179 32.3889C6.4897 33.1715 7.35374 34.4608 8.37482 34.0351L18.5586 29.7895H27.9056H28.9056V28.7895V17.5V16.5ZM2.8679 23.1447C2.8679 20.6475 5.06974 18.5 7.94337 18.5V16.5C4.10622 16.5 0.867903 19.407 0.867903 23.1447C0.867903 26.8825 4.10622 29.7895 7.94337 29.7895V27.7895C5.06974 27.7895 2.8679 25.642 2.8679 23.1447Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});

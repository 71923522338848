import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export const Square: FC<{ fill?: string }> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="32" viewBox="0 0 32 32" width="32" xmlns="http://www.w3.org/2000/svg">
      <rect fill={fill} height="30" stroke="black" strokeWidth="2" width="30" x="1" y="1" />
    </svg>
  );
});

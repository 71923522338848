import { keyframes } from '@emotion/react';
import type { Maybe } from '@ours/types';
import type { BrandColors } from '@ours/utils';

import { useColorEnum } from './useColorEnum';

const slowMovingBG = keyframes`
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
`;
const animation = `${slowMovingBG} 20s ease infinite`;

const bluePink = `linear-gradient(
  -45deg,
  rgba(105, 161, 242, 1) 0%,
  rgba(67, 143, 253, 1) 25%,
  rgba(255, 128, 215, 1) 51%,
  rgba(255, 166, 227, 1) 100%
);`;

const bgImageLookup: Record<BrandColors, string> = {
  beige: '',
  blue: `linear-gradient(
   -45deg,
   rgba(84, 150, 245, 1) 0%,
   rgba(105, 161, 242, 1) 25%,
   rgba(119, 202, 249, 1) 51%,
   rgba(135, 226, 255, 1) 100%
 );`,
  blueDisabled: '',
  blueHover: '',
  orange: '',
  orangeDisabled: '',
  orangeHover: '',
  pink: bluePink,
  pinkDisabled: '',
  pinkHover: '',
  red: '',
  redDisabled: '',
  redHover: '',
  yellow: '',
  yellowDisabled: '',
  yellowHover: '',
};

export const useColorGradient = (
  prefColor: Maybe<BrandColors>,
  defaultColor: BrandColors,
  isEnabled: boolean
) => {
  const color = useColorEnum(prefColor, defaultColor);
  const bgImage = bgImageLookup[prefColor || defaultColor];

  return isEnabled && bgImage
    ? {
        animation,
        bgImage,
        bgSize: '300% 300%',
      }
    : { bg: color };
};

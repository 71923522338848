import { useBoolean } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { EventFieldsFragment } from '@ours/utils';
import { unexpected } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useEvent } from '../../../hooks/useEvent';
import { useInterval } from '../../../hooks/useInterval';
import { useSessionReschedule } from '../../../hooks/useSessionReschedule';
import { useDashboardContext } from '../../DashboardContainer/DashboardContext';
import { MembershipSessionScheduler } from '../../MembershipSessionScheduler/MembershipSessionScheduler';

import { LiveEvent } from './LiveEvent';

interface Props {
  event: Maybe<EventFieldsFragment>;
  membershipId: string;
  sessionId: string;
  sessionTitle: Maybe<string>;
}

export const LiveHeroEventContainer: FC<Props> = memo(
  ({ event, membershipId, sessionId, sessionTitle }) => {
    const { data, loading, refetch } = useDashboardContext();
    const [isOpen, isOpenCtl] = useBoolean(false);
    const guideName = data?.guide?.fullName;
    const joinUrl = event?.joinUrl;
    const startTime = event?.startTime;
    const endTime = event?.endTime;
    const checkForSession = useEvent(() => {
      if (!startTime && !loading) {
        refetch();
      }
    });
    useInterval(checkForSession, 2000);

    const onJoinClick = useEvent(() => {
      window.open(joinUrl || '');
    });

    const { isRescheduleOpen, offReschedule, onStartReschedule } = useSessionReschedule(
      isOpenCtl.on
    );
    const onRescheduleLocal = useEvent(() => {
      onStartReschedule({ startTime });
    });

    if (!event) {
      throw unexpected({ name: 'ScheduledNoRoadmapMissingEvent' });
    }

    return (
      <>
        <MembershipSessionScheduler
          isOpen={isRescheduleOpen}
          isThrottled
          membershipId={membershipId}
          onClose={offReschedule}
          sessionId={sessionId}
        />
        <LiveEvent
          endTime={endTime}
          guideName={guideName}
          isOpen={isOpen}
          joinUrl={joinUrl}
          onClose={isOpenCtl.off}
          onJoinClick={onJoinClick}
          onReschedule={onRescheduleLocal}
          startTime={event.startTime}
          title={sessionTitle}
        />
      </>
    );
  }
);

import { Center, Flex, Grid, keyframes } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import { Border } from '@ours/utils';
import { Close } from '@ours/web-icons';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import type { FC } from 'react';
import { memo, useState } from 'react';

import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useEvent } from '../../hooks/useEvent';
import { Button } from '../Buttons/Button';

const colors = {
  blue: { bg: 'brand.blue', bgHover: 'brand.blueHover', color: 'black' },
  red: { bg: 'brand.red', bgHover: 'brand.redHover', color: 'white' },
} as const;

export interface BannerProps {
  href: Maybe<string>;
  inNewTab?: boolean;
  prefColor?: keyof typeof colors;
  text: Maybe<string>;
}
const bannerEntry = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Banner: FC<BannerProps> = memo(
  ({ href, inNewTab = false, prefColor = 'blue', text }) => {
    const [dismissed, setDismissed] = useState(false);
    const onBtnClick = useEvent(() => setDismissed(true));
    const { bg, bgHover, color } = colors[prefColor];
    const { trackEvent } = useAnalyticsEvent();
    const onTrackingClick = useEvent(() => {
      trackEvent({ type: 'BANNER_CLICK' });
    });
    if (!href || !text) {
      return null;
    }

    return (
      <AnimatePresence>
        {!dismissed ? (
          <motion.div
            animate={{ opacity: 1, rotate: '0', translateY: '0' }}
            exit={{ dur: 0.3, opacity: 0, rotate: '5deg', translateY: '-100%' }}
            initial={{ opacity: 1, rotate: '0', translateY: '0' }}
          >
            <Grid
              animation={`${bannerEntry} 0.8s ease-out calc(1.25s * 0.9) forwards`}
              opacity={0}
              templateColumns="1fr min-content"
              transform="translateY(50%)"
            >
              <Center
                _hover={{ bg: bgHover, color: 'white', cursor: href ? 'pointer' : 'auto' }}
                as="aside"
                bg={bg}
                border={Border}
                borderLeftRadius="9999px"
                color={color}
                onClick={onTrackingClick}
                px="200"
                py={{ base: '50', md: '16px' }}
                textStyle={{ base: 'xs', md: 'md' }}
                transition="all 0.3s"
              >
                {href ? (
                  <Link href={href} rel="noreferrer" target={inNewTab ? '_blank' : undefined}>
                    {text}
                  </Link>
                ) : (
                  <span>{text}</span>
                )}
              </Center>
              <Flex alignItems="center">
                <Button
                  as="button"
                  onClick={onBtnClick}
                  size="roundLg"
                  type="button"
                  variant="yellow"
                >
                  <Close />
                </Button>
              </Flex>
            </Grid>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  }
);

import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export const Diagonal: FC<{ fill?: string }> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="36" viewBox="0 0 43 42" width="36" xmlns="http://www.w3.org/2000/svg">
      <rect
        fill={fill}
        height="27.09"
        stroke="black"
        strokeWidth="2"
        transform="rotate(45 21 1.91421)"
        width="27.09"
        x="21"
        y="1.91421"
      />
    </svg>
  );
});

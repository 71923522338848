import { Box, Center, Flex } from '@chakra-ui/react';
import { Border, RoundedCorners } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export interface DashboardCTABlockProps {
  Button: FC;
  Child?: FC;
  Icon: FC;
  description: string;
  title: string;
}

export const DashboardCTABlock: FC<DashboardCTABlockProps> = memo(
  ({ Button, Child, Icon, description, title }) => {
    return (
      <Flex
        bg="white"
        border={Border}
        flexDir="column"
        gap="300"
        pos="relative"
        px={{ base: '300', md: '500' }}
        py="300"
        w="full"
        {...RoundedCorners.Rotating}
      >
        {Child ? <Child /> : null}
        <Flex gap="200" w="full">
          <Box flex="none" w="33px">
            <Icon />
          </Box>

          <Flex flexDir="column" flexGrow={1} gap="200" w="full">
            <Box textStyle="xl">{title}</Box>
            <Box textStyle="xs">{description}</Box>
          </Flex>
        </Flex>

        <Center>
          <Button />
        </Center>
      </Flex>
    );
  }
);

import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../components/Buttons/Button';
import type { ButtonProps } from '../../components/Buttons/lib/types';

export interface ScheduleLiveSessionProps {
  ctaText: string;
  isDisabled?: boolean;
  onOpen: () => void;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

export const ScheduleLiveSession: FC<ScheduleLiveSessionProps> = memo(
  ({ ctaText, isDisabled, onOpen, size = 'base', variant = 'blue' }) => {
    return (
      <>
        <Button
          as="button"
          isDisabled={isDisabled}
          onClick={onOpen}
          size={size}
          type="button"
          variant={variant}
        >
          {ctaText}
        </Button>
      </>
    );
  }
);

import { toContentSession } from '@ours/utils';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { useEvent } from '../../../hooks/useEvent';
import { MINI_ROADMAP_CONTENT } from '../miniRoadmapContent';

interface Props {
  canJoinModuleSession: boolean;
  membershipId: string;
  sessionId: string;
  state: 'replay' | 'resume' | 'joinModule' | 'futureModule' | 'liveSession';
  title: string;
}

export const JoinModuleButton: FC<Props> = memo(
  ({ canJoinModuleSession, membershipId, sessionId, state, title }) => {
    const text =
      state === 'replay'
        ? MINI_ROADMAP_CONTENT.REPLAY
        : state === 'resume'
        ? MINI_ROADMAP_CONTENT.RESUME
        : MINI_ROADMAP_CONTENT.JOIN;
    const { push } = useRouter();
    const onClick = useEvent(() => {
      push(toContentSession({ membershipId, sessionId, title }));
    });

    return (
      <Button
        as="button"
        isDisabled={!canJoinModuleSession}
        onClick={onClick}
        size="rightRounded"
        type="button"
      >
        {text}
      </Button>
    );
  }
);

export interface Weather {
  icon: 'sun' | 'snow' | 'rain' | 'cloud' | 'night';
  text: string;
}

export const DASHBOARD_WEATHER: Weather[] = [
  { icon: 'snow', text: "Snowflakes are falling, but it's warm with you" },
  { icon: 'sun', text: 'Today is sunny with a chance of love' },
  { icon: 'rain', text: "It's raining so let's watch netflix" },
  { icon: 'cloud', text: 'Cloudy with a chance of hugs' },
  { icon: 'night', text: "Good night moon, good night you... we're tired" },
  { icon: 'night', text: "I'll love you to the moon and back" },
  { icon: 'sun', text: 'Sunny with a chance of flowers' },
  { icon: 'rain', text: '100% chance of rain + hugs' },
  { icon: 'snow', text: "Baby, it's cold outside" },
  { icon: 'snow', text: "It's cold, let's snuggle" },
  { icon: 'sun', text: "It's hot, but not as hot as you are" },
  { icon: 'sun', text: 'Is it hot out or is that just you?' },
  { icon: 'snow', text: "Let's go build a snowman" },
  { icon: 'cloud', text: "It's gloomy outside, let's stay in" },
  { icon: 'rain', text: 'Is that lightning or just a brilliant new idea?' },
  { icon: 'snow', text: "It's cuddle weather" },
  { icon: 'sun', text: "It's not always rainbows & butterflies, they say, but with you it is" },
  { icon: 'rain', text: "Let's go rainbow chasing, my dear" },
  { icon: 'snow', text: 'This is a sign that it’s time to build an igloo together' },
  { icon: 'rain', text: "Thunder's clapping, we're applauding" },
  { icon: 'night', text: "It's wearing-our-PJ's-inside-out kind of weather" },
  { icon: 'sun', text: "I'm always smelling the roses when I'm with you" },
  { icon: 'sun', text: "I'll bask in your warmth" },
  { icon: 'sun', text: 'Gentle breeze with a side of sweet nothings' },
  { icon: 'cloud', text: 'Whatever Al Roker says' },
  { icon: 'night', text: 'Calm before the love storm' },
  { icon: 'night', text: "The stars couldn't shine as bright as you" },
  {
    icon: 'night',
    text: 'Stars are billions of years in the making, and here they are shining for us',
  },
  { icon: 'rain', text: "Bring your umbrella because it's pouring love" },
  { icon: 'sun', text: "The sunshine's in my pocket" },
  { icon: 'sun', text: 'The most beautiful sunshine you ever did see' },
  { icon: 'sun', text: 'Day a million of paradise with you' },
  { icon: 'night', text: 'Any weather with you is love weather' },
  { icon: 'sun', text: "It's a beautiful day to have a beautiful day" },
  { icon: 'snow', text: 'Your heart could melt all of the ice away' },
  { icon: 'rain', text: 'Pretty strong winds as you blow me away' },
  { icon: 'rain', text: "I'll go wherever the wind and you take me" },
  { icon: 'sun', text: 'You are my sunshine' },
  { icon: 'rain', text: 'Is that humidity in the air or is it love?' },
  { icon: 'snow', text: 'Great day to catch snowflakes and feelings' },
  { icon: 'night', text: "Today's forecast is full of dreams" },
  { icon: 'rain', text: 'Slight chance of some kind of eruption' },
  { icon: 'sun', text: 'Strong tides, strong love' },
  { icon: 'night', text: 'Surf conditions say you should ride this wave with me' },
  { icon: 'rain', text: "It's foggy out but my love for you is clear as day" },
  { icon: 'rain', text: "I'm singing (a love song) in the rain" },
  { icon: 'rain', text: 'Raindrops and love keep falling on my head' },
  { icon: 'snow', text: "It's hot chocolate & blanket weather" },
  { icon: 'snow', text: "It's frolicking weather, baby" },
];

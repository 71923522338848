import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const EmailSending: FC<Props> = memo(() => {
  return (
    <svg
      fill="none"
      height="82"
      viewBox="0 0 235 82"
      width="235"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M207.447 81H98.7266L108.77 11.8649C109.208 8.84717 110.72 6.08817 113.028 4.09294C115.336 2.09771 118.287 0.99972 121.339 1H233.552L224.01 66.6863C223.431 70.6619 221.439 74.2964 218.397 76.9249C215.356 79.5533 211.469 80.9999 207.447 81Z"
        fill="#FFA6E3"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M151.368 64.3719L133.355 37.9687C132.322 36.6942 131.83 35.0671 131.982 33.4348C132.134 31.8025 132.919 30.2944 134.17 29.2323C134.809 28.6889 135.551 28.2789 136.351 28.0265C137.152 27.7741 137.995 27.6844 138.83 27.7627C139.666 27.841 140.478 28.0857 141.217 28.4823C141.957 28.8789 142.609 29.4195 143.137 30.0721L155.035 49.0092L193.635 17.25L200.323 25.3967L153.23 64.3719C152.625 64.8828 151.792 64.8828 151.368 64.3719Z"
        fill="#FF7760"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M34.419 7.63281H99.7913C99.7913 8.67538 99.5858 9.70775 99.1864 10.671C98.787 11.6342 98.2016 12.5094 97.4636 13.2466C95.9732 14.7354 93.9518 15.5719 91.844 15.5719H26.4717C26.4717 13.4663 27.309 11.447 28.7994 9.95811C30.2898 8.46925 32.3112 7.63281 34.419 7.63281Z"
        fill="#FFAB00"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M34.419 34.2695H99.7913C99.7913 36.3751 98.954 38.3944 97.4636 39.8833C95.9732 41.3722 93.9518 42.2086 91.844 42.2086H26.4717C26.4717 40.103 27.309 38.0837 28.7994 36.5948C30.2898 35.106 32.3112 34.2695 34.419 34.2695Z"
        fill="#FFAB00"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M8.94733 20.9492H99.7915C99.792 21.9922 99.5869 23.025 99.1878 23.9888C98.7887 24.9525 98.2034 25.8282 97.4653 26.5659C96.7273 27.3036 95.851 27.8888 94.8865 28.2881C93.922 28.6873 92.8882 28.8928 91.8441 28.8928H1C0.999403 27.8499 1.20453 26.817 1.60365 25.8533C2.00277 24.8895 2.58807 24.0138 3.32611 23.2761C4.06415 22.5384 4.94045 21.9532 5.90496 21.554C6.86948 21.1547 7.90329 20.9492 8.94733 20.9492Z"
        fill="#FFAB00"
        stroke="black"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </svg>
  );
});

/* eslint-disable max-lines-per-function */
import type { FC } from 'react';
import { memo } from 'react';

export const Sun: FC = memo(() => {
  return (
    <svg fill="none" height="52" viewBox="0 0 52 52" width="52" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.9427 25.9687C33.9427 28.0782 33.1047 30.1014 31.613 31.593C30.1214 33.0847 28.0982 33.9227 25.9887 33.9227C23.8771 33.9228 21.8517 33.0854 20.3564 31.5944C18.8612 30.1034 18.0182 28.0803 18.0122 25.9687C18.0182 23.855 18.8604 21.8297 20.355 20.335C21.8496 18.8404 23.875 17.9981 25.9887 17.9922C28.1003 17.9982 30.1234 18.8412 31.6144 20.3364C33.1054 21.8317 33.9427 23.8571 33.9427 25.9687Z"
        fill="#FFAB00"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M41.1753 26.0117H50.9995"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M1 26.0117H10.8242"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M26.0112 10.8242V1"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M26.0112 51V41.1758"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M37.0522 14.9479L43.9923 8.00781"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.00781 43.9947L14.9479 37.0547"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M37.0522 37.0547L43.9923 43.9947"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M8.00781 8.00781L14.9479 14.9479"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

import type { DashboardMiniRoadmapStates } from '../DashboardContainer/lib/useDetermineDashboardRoadmapState';
import { useDetermineDashboardRoadmapState } from '../DashboardContainer/lib/useDetermineDashboardRoadmapState';
import { MiniRoadmapViewContainer } from '../MiniRoadmap/MiniRoadmapViewContainer';

interface Props {}

const stateToComp: Record<DashboardMiniRoadmapStates, FC> = {
  loading: () => null,
  miniRoadmap: MiniRoadmapViewContainer,
};

export const DashboardRoadmap: FC<Props> = memo(() => {
  const state = useDetermineDashboardRoadmapState();
  const Component = stateToComp[state];
  return <Component />;
});

/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import type { Maybe } from '@ours/types';

import type { SessionProgress } from '../generated/operations';
import { SessionType } from '../generated/operations';
// eslint-disable-next-line no-restricted-imports
import { uuid } from '../id/uuid';
import { append } from '../lang/append';
import { first } from '../lang/first';

interface ParitalProps {
  completed: boolean;
  currentSessionProgress: number;
}

const _isPartialComplete = ({ completed, currentSessionProgress }: ParitalProps): boolean => {
  return currentSessionProgress > 75 || !!completed;
};
export type SessionListItemType = {
  id: string;
  sessionDescription: string;
  signedCardDeckUrl: string;
  title: string;
  type: SessionType;
};
export const sessionsToListItem = (
  sessions: readonly SessionListItemType[],
  scheduledEventsBySessionId: Record<
    string,
    { joinUrl?: Maybe<string>; startTime?: Maybe<string> }
  >,
  progById: Record<string, Partial<SessionProgress>>,
  membershipId: string
): SessionListItemProps[] => {
  const firstId = first(sessions)?.id;
  if (!firstId) {
    return [];
  }

  let previousSessionPartiallyCompleted = _isPartialComplete({
    completed: !!progById[firstId]?.completed,
    currentSessionProgress: progById[firstId]?.currentSessionProgress || 0,
  });

  return sessions.reduce((acc, cur, idx) => {
    const canJoinModuleSession =
      cur.type === 'MODULE' && (previousSessionPartiallyCompleted || idx === 0);
    const canScheduleLiveSession = cur.type === 'LIVE' && !scheduledEventsBySessionId[cur.id];
    const currentModuleProgress = progById[cur.id]?.currentSessionProgress || 0;
    const isPartialComplete = _isPartialComplete({
      completed: !!progById[cur.id]?.completed,
      currentSessionProgress: currentModuleProgress,
    });
    const hasProgress = currentModuleProgress > 0;
    const isComplete = currentModuleProgress === 100;
    const isFirstSessionNotPartiallyComplete =
      previousSessionPartiallyCompleted === true && isPartialComplete === false;
    previousSessionPartiallyCompleted = isPartialComplete;

    // test this?
    const state =
      cur.type === 'LIVE'
        ? 'liveSession'
        : isComplete
        ? 'replay'
        : hasProgress
        ? 'resume'
        : canJoinModuleSession
        ? 'joinModule'
        : 'futureModule';

    return append(
      {
        canJoinModuleSession,
        canScheduleLiveSession,
        cardDeckSignedUrl: cur.signedCardDeckUrl,
        currentModuleProgress,
        description: cur.sessionDescription || undefined,
        hasProgress,
        id: uuid(),
        idx,
        isComplete,
        isFirstSessionNotPartiallyComplete,
        isPartialComplete,
        joinUrl: scheduledEventsBySessionId[cur.id]?.joinUrl,
        membershipId,
        sessionId: cur.id,
        startTime: scheduledEventsBySessionId[cur.id]?.startTime,
        state,
        title: cur.title || '',
        type: cur.type || SessionType.Module,
      },
      acc
    );
  }, [] as SessionListItemProps[]);
};
export interface SessionListItemProps {
  canJoinModuleSession: boolean;
  canScheduleLiveSession: boolean;
  cardDeckSignedUrl: Maybe<string>;
  /** 0->100 */
  currentModuleProgress: number;
  description: string | undefined;
  guideName?: string;
  hasProgress: boolean;
  id: string;
  idx: number;
  isComplete: boolean;
  isFirstSessionNotPartiallyComplete: boolean;
  isPartialComplete: boolean;
  joinUrl: Maybe<string>;
  membershipId: string;
  sessionId: string;
  startTime: Maybe<string>;
  state: 'replay' | 'resume' | 'joinModule' | 'futureModule' | 'liveSession';
  title: string;
  type: Maybe<SessionType> | 'more';
}

export const DASHBOARD_HERO_BOX = {
  FIRST_CTA: 'Click to Schedule',
  FIRST_SUB_HEADING: 'Schedule your first session to meet your Guide.',
  ITS_LIVE_TAG_LINE: 'Happening soon!',
  JOIN_NOW: 'Join Now',
  SUBSEQUENT_CTA: 'Click to Schedule',
  SUBSEQUENT_SUB_HEADING: 'Your next session will be live with your Guide.',
  TAGLINE_CONTINUE: 'Keep Going!',
  TAGLINE_NEW: 'Up Next',
};

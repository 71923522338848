import { Flex } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { CalendarEvent } from 'calendar-link';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { AddToCalendar } from '../../../../components/AddToCalendar/AddToCalendar';
import { UnderlinedLink } from '../../../../components/Links/UnderlinedLink';
import { UNDERLINE_LINK_PROPS } from '../../../../components/Links/underlineLinkProps';

interface Props {
  _onReschedule: () => void;
  endTime: Maybe<string>;
  joinUrl: Maybe<string>;
  startTime: Maybe<string>;
  title: Maybe<string>;
}

export const LiveHeroFooter: FC<Props> = memo(
  ({ _onReschedule, endTime, joinUrl, startTime, title }) => {
    const event: CalendarEvent = useMemo(
      () => ({
        duration: endTime ? undefined : [45, 'minutes'],
        end: endTime,
        location: joinUrl || '',
        start: startTime,
        title: title || '',
      }),
      [title, joinUrl, startTime, endTime]
    );
    const menuProps = useMemo(
      () => ({
        'data-testid': 'addToCalendar',
        fontSize: '300',
        ...UNDERLINE_LINK_PROPS,
      }),
      []
    );

    return (
      <Flex alignItems="center" fontSize={'300'} gap="100" pt="200">
        <AddToCalendar event={event} menuButtonProps={menuProps} triggerText="Add To Calendar" />
        <UnderlinedLink
          as="button"
          data-testid="LiveHeroEvent.rescheduleButton"
          onClick={_onReschedule}
          type="button"
        >
          Reschedule
        </UnderlinedLink>
      </Flex>
    );
  }
);

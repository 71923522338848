import { Box, Flex } from '@chakra-ui/react';
import type { SessionListItemProps } from '@ours/utils';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { UnderlinedLink } from '../../../components/Links/UnderlinedLink';
import { useDayjs } from '../../../hooks/date/useDayjs';
import { MINI_ROADMAP_CONTENT } from '../miniRoadmapContent';
import type { SchedulingCB } from '../types';

export const LiveExtraDescription: FC<SessionListItemProps & SchedulingCB> = memo(
  ({ isComplete, onStartReschedule, sessionId, startTime }) => {
    const formattedStartDate = useDayjs(startTime, {
      fallbackText: 'Coming soon...',
      format: 'MonthDayTime',
    });
    const onClick = useCallback(() => {
      onStartReschedule(sessionId);
    }, [onStartReschedule, sessionId]);

    return (
      <Flex gap="50" textStyle="xs">
        {!startTime ? null : <Box as="span">{formattedStartDate}</Box>}
        {isComplete || !startTime ? null : (
          <>
            <Box as="span">•</Box>
            <UnderlinedLink as="button" fontWeight="600" onClick={onClick} type="button">
              {MINI_ROADMAP_CONTENT.RESCHEDULE}
            </UnderlinedLink>
          </>
        )}
      </Flex>
    );
  }
);

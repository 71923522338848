import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export const Triangle: FC<{ fill?: string }> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="32" viewBox="0 0 48 35" width="46" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50311 33.5L24 1.7824L45.4969 33.5H2.50311Z"
        fill={fill}
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

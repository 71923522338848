import type { Maybe } from '@ours/types';

import { toShortId } from '../id/toShortId';

import { CoupleRoutes } from './RouteConstants';

export const toSessionCapsule = (sessionId: Maybe<string>): string => {
  if (!sessionId) {
    return CoupleRoutes.Home;
  }

  return `${CoupleRoutes.Capsule}/${toShortId(sessionId)}`;
};

/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { Badge, Box, Flex } from '@chakra-ui/react';
import type { SessionListItemProps, SessionType } from '@ours/utils';
import { Border, Colors } from '@ours/utils';
import { DownloadButtonIcon } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { Tooltip } from '../../../components/Tooltip/Tooltip';
import { useDownloadCardDeck } from '../../../hooks/useDownloadCardDeck';
import { ShapeByIDX } from '../../ShapeByIDX';
import { MINI_ROADMAP_CONTENT } from '../miniRoadmapContent';
import type { SchedulingCB } from '../types';

import { JoinModuleButton } from './JoinModuleButton';
import { ListItemProgress } from './ListItemProgress';
import { LiveButton } from './LiveButton';
import { LiveExtraDescription } from './LiveExtraDescription';

const CTAButtons: Record<SessionType | 'more', FC<SessionListItemProps & SchedulingCB> | null> = {
  LIVE: LiveButton,
  MODULE: JoinModuleButton,
  more: () => null,
};

export const DashboardSessionListItem: FC<SessionListItemProps & SchedulingCB> = memo((props) => {
  const {
    cardDeckSignedUrl,
    currentModuleProgress,
    guideName,
    idx,
    isComplete,
    isFirstSessionNotPartiallyComplete,
    isPartialComplete,
    sessionId,
    state,
    title,
    type,
  } = props;
  const Button = type ? CTAButtons[type] : null;
  const listItemBgColor =
    type === 'more' ? 'ui.gray.07' : isFirstSessionNotPartiallyComplete ? 'white' : 'brand.beige';
  const shapeFill =
    !isPartialComplete && !isFirstSessionNotPartiallyComplete && state !== 'joinModule'
      ? Colors.brand.beige
      : undefined;
  const [onClick] = useDownloadCardDeck('miniRoadmap', {
    cardDeckSignedUrl,
    id: sessionId,
    title,
  });
  return (
    <Flex gap="300">
      <Flex alignItems="center" display={{ base: 'none', lg: 'flex' }} sx={{ svg: { w: '45px' } }}>
        <ShapeByIDX fill={shapeFill} idx={idx} />
      </Flex>
      <Flex
        bg={listItemBgColor}
        border={Border}
        borderTopRightRadius="900"
        data-testid="dashboardSessionListItem"
        gap="300"
        justifyContent="space-between"
        pos="relative"
        px="200"
        py="300"
        w="full"
      >
        <Flex
          alignItems="center"
          data-testid="listItem.progress"
          gap="100"
          justifyContent="space-between"
          w={{ base: '80%' }}
        >
          <Flex flexDir="column" gap="50">
            <Flex fontWeight="600" gap="100" textStyle="md">
              {title}

              {type === 'LIVE' && !isComplete && guideName ? (
                <Box display={{ base: 'none', lg: 'block' }}>
                  <Badge bg="brand.pink">
                    {MINI_ROADMAP_CONTENT.WITH_GUIDE} {guideName}
                  </Badge>
                </Box>
              ) : null}
              {isPartialComplete && cardDeckSignedUrl ? (
                <Tooltip label="Download Card Deck">
                  <Box as="button" display="inline-block" h="24px" onClick={onClick} type="button">
                    <DownloadButtonIcon h="100%" />
                  </Box>
                </Tooltip>
              ) : null}
            </Flex>

            {type === 'LIVE' ? <LiveExtraDescription {...props} /> : null}
          </Flex>

          {type === 'more' ? null : (
            <ListItemProgress
              currentModuleProgress={currentModuleProgress}
              listItemBgColor={listItemBgColor}
            />
          )}
        </Flex>
        <Flex alignItems="center" pr="20px" w="130px">
          {Button ? <Button {...props} /> : null}
        </Flex>
      </Flex>
    </Flex>
  );
});

import type { NextPageWithLayout } from '@ours/types';
import {
  DashboardWithMenuLayout,
  InitializeCoupleAppDocument,
  LiveDashboardContextProvider,
  getApolloClient,
  getCurrentSession,
  isSSR,
} from '@ours/web-utils';
import { memo } from 'react';

const hydrateCoupleDoc = async () => {
  const isLoggedIn = await getCurrentSession();
  if (isLoggedIn) {
    const client = getApolloClient();
    client.query({ query: InitializeCoupleAppDocument });
  }
};

if (!isSSR()) {
  hydrateCoupleDoc();
}

const DashboardPage: NextPageWithLayout = memo(() => {
  return <LiveDashboardContextProvider />;
});

DashboardPage.Layout = DashboardWithMenuLayout;

export default DashboardPage;

import type { FC } from 'react';
import { memo } from 'react';

export const Rain: FC = memo(() => {
  return (
    <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.4734 5C24.2289 5 12.0087 24.8142 12.0087 31.7623C11.9458 33.4512 12.224 35.1354 12.8269 36.7143C13.4298 38.2932 14.3448 39.7342 15.5174 40.9514C16.69 42.1685 18.096 43.1366 19.6513 43.7979C21.2066 44.4592 22.8793 44.8 24.5694 44.8C26.2594 44.8 27.9321 44.4592 29.4874 43.7979C31.0428 43.1366 32.4487 42.1685 33.6213 40.9514C34.7939 39.7342 35.709 38.2932 36.3118 36.7143C36.9147 35.1354 37.193 33.4512 37.13 31.7623C37.0427 24.8142 24.6828 5 24.4734 5Z"
        fill="#69A1F2"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});

import { Box } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC } from 'react';
import { memo, useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper/types';

import { Button } from '../../components/Buttons/Button';
import { NextAndPrevArrows } from '../../components/Slider/lib/NextAndPrevArrows';
import { useEvent } from '../../hooks/useEvent';

export interface ContentBox {
  description: string;
  link: string;
  title: string;
}
export interface ContentBoxSliderProps {
  boxes: ContentBox[];
}

export const ContentBoxSlider: FC<ContentBoxSliderProps> = memo(({ boxes }) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const onSwiperLocal = useEvent((swiper: SwiperType) => {
    setSwiper(swiper);
  });

  return (
    <Box pos="relative">
      <NextAndPrevArrows isHovering isLooped={false} isOpaque swiper={swiper} />
      <Swiper
        allowTouchMove={false}
        modules={[Pagination]}
        observeParents
        observer
        onSwiper={onSwiperLocal}
        slidesPerView="auto"
        spaceBetween={30}
      >
        {boxes.map((box, idx) => (
          <SwiperSlide key={idx} style={{ display: 'flex', width: '360px' }}>
            <Box bg="brand.pink" border={Border} borderRadius="750" p="300" w="full">
              <Box fontWeight="700" pb="100" textStyle={{ base: 'sm', md: 'lg' }}>
                {box.title}
              </Box>
              <Box pb="300" textStyle={{ base: '2xs', md: 'xs' }}>
                {box.description}
              </Box>
              <Box>
                <Button as="a" href={box.link} size="sm" variant="beige">
                  Capsule
                </Button>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
});

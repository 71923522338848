import { Box, Divider, Flex } from '@chakra-ui/react';
import { WeatherIconMap } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { useRandomItem } from '../../../hooks/useRandomItem';

import { DASHBOARD_WEATHER } from './content';

interface Props {}

export const WeatherStatement: FC<Props> = memo(() => {
  const [state] = useRandomItem(DASHBOARD_WEATHER);
  const Icon = state?.icon ? WeatherIconMap[state.icon] : null;

  return (
    <Flex alignItems="center" display={{ base: 'none', md: 'flex' }} gap="20px">
      <Box display={{ base: 'none', md: 'block' }} h="88px" pr="400">
        <Divider orientation="vertical" />
      </Box>
      <Box>{Icon ? <Icon /> : null}</Box>
      <Box maxW="100%" textStyle={{ base: 'sm', md: 'md' }} w={{ base: '125px', lg: '300px' }}>
        {state?.text || null}
      </Box>
    </Flex>
  );
});

import type { ApolloError } from '@apollo/client';
import { noop } from '@ours/utils';
import type { FC } from 'react';
import { createContext, memo, useContext } from 'react';

import type { DashboardCoupleAppFragment } from '../../generated/custom-hooks';
import {
  useFindDashboardMembershipQuery,
  useInitializeCoupleAppQuery,
} from '../../generated/custom-hooks';
import { useFocusAwarePolling } from '../../hooks/useFocusAwarePolling';

import { Dashboard } from './Dashboard';

export interface IDashboardContext {
  data: DashboardCoupleAppFragment | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch: () => Promise<any>;
}

export const DashboardContext = createContext<IDashboardContext>({
  data: undefined,
  error: undefined,
  loading: false,
  refetch: noop,
});
export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export const LiveDashboardContextProvider: FC = memo(() => {
  const { data, error, loading, refetch, startPolling, stopPolling } =
    // NOTE: avoid batching this query. it will slow down the app.
    useInitializeCoupleAppQuery();
  useFocusAwarePolling({ pollInterval: 10000, refetch, startPolling, stopPolling });

  return (
    <DashboardContext.Provider
      value={{
        data: data?.fetchSelf?.latestMembership || undefined,
        error,
        loading,
        refetch,
      }}
    >
      <Dashboard />
    </DashboardContext.Provider>
  );
});
export const ImpersonationDashboardContextProvider: FC<{
  membershipId: string;
}> = memo(({ membershipId }) => {
  const { data, error, loading, refetch } = useFindDashboardMembershipQuery({
    variables: { id: membershipId },
  });

  return (
    <DashboardContext.Provider
      value={{ data: data?.findMembership || undefined, error, loading, refetch }}
    >
      <Dashboard />
    </DashboardContext.Provider>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { BoxComponent } from '../BoxComponent';
import { DASHBOARD_HERO_BOX } from '../content';

export interface Props {
  description: string;
  hasProgress: boolean;
  onStartModule: () => void;
  title: string;
}

export const ModuleSession: FC<Props> = memo(
  ({ description, hasProgress, onStartModule, title }) => {
    return (
      <BoxComponent
        CTAButton={() => (
          <Button as="button" onClick={onStartModule} size="roundXl" type="button">
            {hasProgress ? 'Resume Session' : 'Start Session'}
          </Button>
        )}
        content={description}
        description={''}
        tagline={hasProgress ? DASHBOARD_HERO_BOX.TAGLINE_CONTINUE : DASHBOARD_HERO_BOX.TAGLINE_NEW}
        taglineColor="brand.pink"
        titleLn1={title}
        titleLn2={''}
      />
    );
  }
);

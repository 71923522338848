import { CoupleRoutes } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';

import { DashboardCTABlock } from './DashboardCTABlock';
import { SessionStoreIcon } from './SessionStoreIcon';

export interface SessionsStoreAdBlockProps {}

export const SessionsStoreAdBlock: FC<SessionsStoreAdBlockProps> = memo(() => {
  return (
    <DashboardCTABlock
      Button={() => (
        <Button as="a" href={CoupleRoutes.Store} variant="orange" w="full">
          Browse Sessions
        </Button>
      )}
      Icon={SessionStoreIcon}
      description="Check out our library of sessions or book additional time with your Guide."
      title="Get more sessions"
    />
  );
});

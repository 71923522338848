import { Box, Text } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { useModal } from '../../../hooks/useModal';
import { DASHBOARD_PARTNER } from '../content';

interface Props {}

export const InviteYourPartner: FC<Props> = memo(() => {
  const { onOpen } = useModal('invitePartnerModal');

  return (
    <>
      <Text fontSize="700" pb="100">
        {DASHBOARD_PARTNER.HEADING_NO_PARTNER}
      </Text>
      <Text fontSize="400" maxW="180px" pb="300">
        {DASHBOARD_PARTNER.SUB_HEADING_NO_PARTNER}
      </Text>
      <Box>
        <Button as="button" onClick={onOpen} type="button">
          {DASHBOARD_PARTNER.NO_PARTNER_CTA}
        </Button>
      </Box>
    </>
  );
});

import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import { UnderlinedLink } from '../../../components/Links/UnderlinedLink';
import { useEvent } from '../../../hooks/useEvent';
import { useModal } from '../../../hooks/useModal';
import { DASHBOARD_PARTNER } from '../content';

interface Props {
  canInvitePartner: boolean;
  partnersEmail: string;
  partnersName: string;
}

export const YourPartnerBox: FC<Props> = memo(
  ({ canInvitePartner, partnersEmail, partnersName }) => {
    const { openData } = useModal('invitePartnerModal');
    const onClick = useEvent(() => {
      openData({ isResend: true });
    });

    return (
      <>
        <Box fontSize="700" pb="200">
          {DASHBOARD_PARTNER.HEADING_NO_PARTNER}
        </Box>
        {/** If the user invites the wrong person via email, we need to show them how to re-invite someone */}
        <Box>
          {canInvitePartner ? DASHBOARD_PARTNER.INVITE_SENT_TO : DASHBOARD_PARTNER.YOUR_PARTNER_IS}
        </Box>
        <Box pb="300">{partnersName || partnersEmail}</Box>
        {canInvitePartner ? (
          <Box>
            <UnderlinedLink as="button" onClick={onClick} type="button">
              {DASHBOARD_PARTNER.RESEND_INVITE}
            </UnderlinedLink>
          </Box>
        ) : null}
      </>
    );
  }
);

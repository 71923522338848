import { Box, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { useInputState } from '@mantine/hooks';
import type { Dispatch, FC, FormEventHandler, SetStateAction } from 'react';
import { memo, useState } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { Input } from '../../../components/Inputs/Input';
import { useEvent } from '../../../hooks/useEvent';
import { DASHBOARD_PARTNER } from '../../YourPartner/content';

interface Props {
  isResend: boolean;
  onInvite: (email: string) => Promise<void>;
  setState: Dispatch<SetStateAction<'success' | 'invite'>>;
}

export const InvitePartnerModalForm: FC<Props> = memo(({ isResend, onInvite, setState }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useInputState('');
  const onSubmitLocal: FormEventHandler = useEvent(async (e) => {
    e.preventDefault();
    setLoading(true);

    await onInvite(email);
    setLoading(false);
    setState('success');
    setEmail('');
  });

  return (
    <Flex as="form" flexDir="column" maxW="75%" onSubmit={onSubmitLocal} px="400" py="400">
      <Box pb="150" textStyle={{ base: 'xl', md: '2xl' }}>
        {isResend ? DASHBOARD_PARTNER.REINVITE_PARTNER_TEXT : DASHBOARD_PARTNER.INVITE_PARTNER_TEXT}
      </Box>
      <Box pb="200" textStyle={{ base: 'xs', md: 'sm' }}>
        {isResend
          ? DASHBOARD_PARTNER.REINVITE_PARTNER_TEXT
          : DASHBOARD_PARTNER.INVITE_PARTNER_DESCRIPTION}
      </Box>
      <FormControl>
        <FormLabel htmlFor="invitePartnerEmail">{DASHBOARD_PARTNER.INVITE_PARTNER_LABEL}</FormLabel>
        <Input
          id="invitePartnerEmail"
          isRequired
          onChange={setEmail}
          placeholder={DASHBOARD_PARTNER.INVITE_PARTNER_PLACEHOLDER}
          type="email"
          value={email}
        />
        <FormErrorMessage>Please enter a valid email</FormErrorMessage>
      </FormControl>
      <Box pt="300">
        <Button as="button" isLoading={loading} type="submit">
          {isResend ? DASHBOARD_PARTNER.REINVITE_PARTNER_CTA : DASHBOARD_PARTNER.INVITE_PARTNER_CTA}
        </Button>
      </Box>
    </Flex>
  );
});

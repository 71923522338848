import { Box, Flex, useBoolean } from '@chakra-ui/react';
import { useLocalStorage } from '@mantine/hooks';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { memo, useEffect, useMemo, useRef } from 'react';

import { PinkFooter } from '../../features/PinkFooter/PinkFooter';
import { useInitializeCoupleAppQuery } from '../../generated/custom-hooks';
import { isSSR } from '../../lib/isSSR';
import { Banner } from '../Banner/Banner';
import { MenuDrawer } from '../MenuDrawer/MenuDrawer';

import { DashboardNavBar } from './lib/DashboardNavBar';

export const DashboardWithMenuLayout: FC<{ children?: ReactNode }> = memo(
  ({ children: _children }) => {
    const [isOpen, isOpenCtl] = useBoolean(false);
    const children = useMemo(() => _children, [_children]);
    const navButtonRef = useRef<HTMLButtonElement>(null);
    const { pathname } = useRouter();

    const { data } = useInitializeCoupleAppQuery();
    const combinedStudentsName = data?.fetchSelf?.latestMembership?.combinedStudentsName;
    const [storedCombinedStudentsName, setLocalName] = useLocalStorage<null | string>({
      defaultValue: combinedStudentsName || '',
      key: 'localDashboardName',
    });

    useEffect(() => {
      combinedStudentsName && setLocalName(combinedStudentsName);
    }, [setLocalName, combinedStudentsName]);

    return (
      <>
        <Flex bg="brand.beige" data-testid="DashboardWithMenuLayout" flexDir="column" w="full">
          <Box
            maxW="full"
            mb={{ base: '-1px', md: '-2px' }}
            mx="auto"
            pt={{ base: '200', md: '400' }}
            w="container.xl"
          >
            <Banner
              href={data?.getCustomerConfig?.bannerLink}
              inNewTab
              prefColor="red"
              text={data?.getCustomerConfig?.bannerText}
            />
          </Box>
          <Box maxW="full" mx="auto" w="container.xl" zIndex={1}>
            <DashboardNavBar
              combinedStudentsName={storedCombinedStudentsName}
              navButtonRef={navButtonRef}
              onOpen={isOpenCtl.on}
            />
          </Box>
          <Box
            as="main"
            flexGrow={1}
            margin="auto"
            maxW="container.xl"
            position="relative"
            px={{ base: '200', md: '600' }}
            py={{ base: '300', md: '600' }}
            w="full"
          >
            {children}
          </Box>
          <PinkFooter />
        </Flex>

        {isSSR() ? null : (
          <MenuDrawer
            activeRoute={pathname}
            isOpen={isOpen}
            navButtonRef={navButtonRef}
            onClose={isOpenCtl.off}
          />
        )}
      </>
    );
  }
);

import { Box } from '@chakra-ui/react';
import { COMPANY_MAIL_TO_LINK } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { UnderlinedLink } from '../../components/Links/UnderlinedLink';
import { DashboardHeroFull } from '../DashboardHeroFull/DashboardHeroFull';

interface Props {}

export const DashboardError: FC<Props> = memo(() => {
  return (
    <DashboardHeroFull
      Body={() => (
        <>
          <Box>We are experiencing some issue right now! Please refresh and try again!</Box>
          <Box>
            Or, you can{' '}
            <UnderlinedLink as="a" fontWeight="600" href={COMPANY_MAIL_TO_LINK} target="_blank">
              Contact us
            </UnderlinedLink>{' '}
            for support.
          </Box>
        </>
      )}
      BodyCTA={null}
      CTAFooter={null}
      subtitle="An error has occurred!"
      title="Hi there, something went wrong"
    />
  );
});

import { unexpected } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { useVariableStrings } from '../../../hooks/variableStrings/useVariableStrings';
import { useDashboardContext } from '../../DashboardContainer/DashboardContext';

import { ScheduleLiveEvent } from './ScheduleLiveEvent';

export interface Props {
  description: string;
  isFirst: boolean;
  onOpen: () => void;
  sessionId: string;
  title: string;
}

export const ScheduleLiveEventContainer: FC<Props> = memo(
  ({ description, isFirst, onOpen, sessionId, title }) => {
    const { data } = useDashboardContext();

    const membershipId = data?.id;
    const descriptionWithVariables = useVariableStrings(description);
    if (!membershipId || !sessionId) {
      throw unexpected({ name: 'MissingDataBeforeScheduledFirstEvent' });
    }

    return (
      <ScheduleLiveEvent
        description={descriptionWithVariables}
        isFirst={isFirst}
        onOpen={onOpen}
        title={title}
      />
    );
  }
);

import { Box, Flex } from '@chakra-ui/react';
import { Border, RoundedCorners, toClockTime } from '@ours/utils';
import { Redo } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { Pin } from '../../../components/Pin';
import { useRandomItem } from '../../../hooks/useRandomItem';

import { PlusMinusButton } from './PlusMinusButton';

const whoGoesFirstPromptOptions = [
  'Whoever hates olives more goes first',
  'Whoever loves to dance the most shares first',
  'Whoever woke up first this morning goes first',
  'Whoever has had the most cups of coffee',
  'Whoever has the longer commute goes first',
  'Whoever has the bigger sweet tooth goes first',
  'Whoever touches their finger to their nose goes first starts',
  'Whoever can correctly guess their partner’s favorite childhood movie fastest goes first',
  'Whoever is sitting on the left of the screen goes first',
  'Whoever prefers vanilla over chocolate goes first',
  'Whoever is younger goes first',
];

export interface DiscussionPromptProps {
  onAddTime: () => void;
  onStart: () => void;
  onSubtractTime: () => void;
  timeInSec: number;
}

export const DiscussionPrompt: FC<DiscussionPromptProps> = memo(
  ({ onAddTime, onStart, onSubtractTime, timeInSec }) => {
    const [whoGoesFirstPrompt, nextPrompt] = useRandomItem(whoGoesFirstPromptOptions);
    return (
      <Pin bg="blackAlpha.700" zIndex={1}>
        <Flex
          alignItems="center"
          flexDir="column"
          gap="100"
          h="full"
          justifyContent="center"
          w="full"
        >
          <Flex
            bg="white"
            border={Border}
            gap="300"
            maxW="80%"
            px="300"
            py="100"
            textAlign={{ base: 'center', md: 'left' }}
            textStyle="sm"
            {...RoundedCorners.Rotating}
          >
            {whoGoesFirstPrompt}{' '}
            <Box as="button" onClick={nextPrompt} sx={{ svg: { maxW: '16px' } }} type="button">
              <Redo />
            </Box>
          </Flex>
          <Flex alignItems="center" gap="400">
            <PlusMinusButton onClick={onSubtractTime}>-</PlusMinusButton>
            <Button as="button" onClick={onStart} size="roundXl" type="button">
              <Box pos="relative" pt="12px">
                Start
                <Box textStyle="2xs">{toClockTime(timeInSec)}</Box>
              </Box>
            </Button>
            <PlusMinusButton onClick={onAddTime}>+</PlusMinusButton>
          </Flex>
        </Flex>
      </Pin>
    );
  }
);

import type { FC } from 'react';
import { memo, useCallback, useState } from 'react';

import { SimpleModal } from '../../components/Modals/SimpleModal';
import { useModal } from '../../hooks/useModal';

import { InvitePartnerModalForm } from './lib/InvitePartnerModalForm';
import { InvitePartnerModalSuccess } from './lib/InvitePartnerModalSuccess';

interface Props {
  onInvite: (email: string) => Promise<void>;
}

export const InvitePartnerModal: FC<Props> = memo(({ onInvite }) => {
  const [state, setState] = useState<'success' | 'invite'>('invite');
  const { isOpen, modalData, onClose } = useModal('invitePartnerModal');
  const isResend = !!modalData?.isResend;
  const onResend = useCallback(() => {
    setState('invite');
  }, []);

  return (
    <SimpleModal isOpen={isOpen} onClose={onClose}>
      {state === 'invite' ? (
        <InvitePartnerModalForm isResend={isResend} onInvite={onInvite} setState={setState} />
      ) : (
        <InvitePartnerModalSuccess onResend={onResend} />
      )}
    </SimpleModal>
  );
});

import { Box, Flex } from '@chakra-ui/react';
import { COMPANY_MAIL_TO_LINK } from '@ours/utils';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Button } from '../../../../components/Buttons/Button';

interface Props {}

export const ReschedulingDisabled: FC<Props> = memo(() => {
  const onClick = useCallback(() => {
    window.open(COMPANY_MAIL_TO_LINK);
  }, []);

  return (
    <Flex data-testid="ReschedulingDisabled" flexDir="column" maxW="80%" px="400" py="600">
      <Box fontSize={{ base: '700', md: '800' }} lineHeight={{ base: '700', md: '800' }} pb="100">
        Rescheduling Expired
      </Box>
      <Box fontSize={{ base: '300', md: '400' }} pb="30">
        <Box>Rescheduling has passed</Box>
        <Box>All live sessions must be rescheduled with at least 24 hours' notice.</Box>
        <Box>
          Your guide will wait 5 minutes for you to join. After that, the session will be forfeited
          as a no show and you'll move forward without the live session.
        </Box>
        <Box>Still have a conflict? Let's figure out next steps together.</Box>
      </Box>
      <Box>
        <Button as="button" onClick={onClick} type="button">
          Contact Us
        </Button>
      </Box>
    </Flex>
  );
});

import { useBoolean } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import { timeRelativity } from '@ours/utils';

import { useEvent } from './useEvent';

interface Props {
  startTime: Maybe<string>;
}

export const useSessionReschedule = (onOpenModal: () => void) => {
  const [isRescheduleOpen, { off: offReschedule, on: onReschedule }] = useBoolean(false);

  const onStartReschedule = useEvent(({ startTime }: Props) => {
    const { isBeforeWithin24Hr, isPast } = timeRelativity(startTime);
    // eslint-disable-next-line no-console
    console.log({ isBeforeWithin24Hr, isPast });
    if (isBeforeWithin24Hr || isPast) {
      onOpenModal();
    } else {
      onReschedule();
    }
  });

  return { isRescheduleOpen, offReschedule, onStartReschedule };
};

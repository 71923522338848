import { Box, Flex } from '@chakra-ui/react';
import type { SessionListItemProps } from '@ours/utils';
import { MiniRoadmapSvgHeader } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { DashboardSessionListItem } from './lib/DashboardSessionListItem';
import type { SchedulingCB } from './types';

export interface Props extends SchedulingCB {
  guideName: string;
  listItemData: SessionListItemProps[];
  membershipId: string;
}

export const MiniRoadmapView: FC<Props> = memo(
  ({ guideName, listItemData, membershipId, onStartReschedule }) => {
    return (
      <Box>
        <Box sx={{ svg: { maxW: 'full' } }}>
          <MiniRoadmapSvgHeader />
        </Box>
        <Flex pos="relative">
          <Box
            bg="black"
            bottom="0"
            display={{ base: 'none', md: 'block' }}
            left="20px"
            pos="absolute"
            top="0"
            w="4px"
          />
          <Flex flexDir="column" gap="200" w="full" zIndex={1}>
            {listItemData.map((item) => (
              <DashboardSessionListItem
                {...item}
                guideName={guideName}
                key={item.id}
                membershipId={membershipId}
                onStartReschedule={onStartReschedule}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    );
  }
);

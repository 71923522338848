/* eslint-disable max-lines-per-function */
import { useBoolean } from '@chakra-ui/react';
import { assertIsNonEmptyString, first, last, toContentSession } from '@ours/utils';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { useVariableStrings } from '../../hooks/variableStrings/useVariableStrings';
import { useDashboardContext } from '../DashboardContainer/DashboardContext';
import { useDashboardListItems } from '../DashboardContainer/lib/useDashboardListItems';
import { useDetermineDashboardRoadmapState } from '../DashboardContainer/lib/useDetermineDashboardRoadmapState';
import { MembershipSessionScheduler } from '../MembershipSessionScheduler/MembershipSessionScheduler';

import { LiveHeroEventContainer } from './LiveEvent/LiveEventContainer';
import { ModuleSession } from './ModuleSession/ModuleSession';
import { ScheduleLiveEventContainer } from './ScheduleLiveEvent/ScheduleLiveEventContainer';

/** IMPROVE: PUll these dashboard events from the main event context in the app machine */
export const DashboardHeroBox: FC = memo(() => {
  const { data } = useDashboardContext();
  const state = useDetermineDashboardRoadmapState();
  const sessions = data?.sessions;
  const events = data?.scheduledEvents;
  const membershipId = data?.id;
  assertIsNonEmptyString(membershipId);
  const listItemData = useDashboardListItems();
  const firstItem = useMemo(() => {
    const fallback = listItemData.every((s) => s.isPartialComplete)
      ? last(listItemData)
      : first(listItemData);

    return (
      listItemData.find(
        ({ isFirstSessionNotPartiallyComplete }) => isFirstSessionNotPartiallyComplete
      ) || fallback
    );
  }, [listItemData]);

  const { push } = useRouter();
  const onStartModule = useCallback(() => {
    push(
      toContentSession({
        membershipId,
        sessionId: firstItem?.sessionId,
        title: firstItem?.title,
      })
    );
  }, [push, membershipId, firstItem]);
  const descriptionWithVariables = useVariableStrings(firstItem?.description);
  const [open, { off, on }] = useBoolean(false);

  if (state === 'loading' || !firstItem) {
    return null;
  }

  if (firstItem?.type === 'LIVE') {
    const session = sessions?.find(({ id }) => id === firstItem.sessionId);
    const event = events?.find((event) => event.sessionId === firstItem.sessionId);
    const isFirst = sessions?.[0]?.id === firstItem.sessionId;

    return (
      <>
        {event ? (
          <LiveHeroEventContainer
            event={event}
            membershipId={membershipId}
            sessionId={firstItem.sessionId}
            sessionTitle={session?.title}
          />
        ) : (
          <ScheduleLiveEventContainer
            description={session?.sessionDescription || ''}
            isFirst={isFirst}
            onOpen={on}
            sessionId={firstItem.sessionId}
            title={session?.title || ''}
          />
        )}
        <MembershipSessionScheduler
          isOpen={open}
          isThrottled
          membershipId={membershipId}
          onClose={off}
          sessionId={firstItem.sessionId}
        />
      </>
    );
  }

  return (
    <ModuleSession
      description={descriptionWithVariables}
      hasProgress={firstItem.hasProgress}
      onStartModule={onStartModule}
      title={firstItem.title}
    />
  );
});

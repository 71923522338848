import { Box, Flex } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { FullCenteredSquiggleLoader } from '../../components/FullCenteredSquiggleLoader/FullCenteredSquiggleLoader';
import { useAppActions } from '../../state/appMachine/externalActions/useAppActions';
import { ActivityCenter } from '../ActivityCenter/ActivityCenter';
import { DashboardEmpty } from '../DashboardEmpty/DashboardEmpty';
import { DashboardError } from '../DashboardError/DashboardError';
import { DashboardHeroBox } from '../DashboardHeroBox/DashboardHeroBox';
import { DashboardRoadmap } from '../DashboardRoadmap/DashboardRoadmap';
import { YourGuide } from '../YourGuide/YourGuide';
import { YourPartnerContainer } from '../YourPartner/YourPartnerContainer';

import { useDashboardContext } from './DashboardContext';
import { DashboardCapsuleCards } from './lib/DashboardCapsuleCards';
import { SessionsStoreAdBlock } from './lib/SessionsStoreAdBlock';

export const Dashboard: FC = memo(() => {
  const { data, error, loading } = useDashboardContext();
  const { onSetTitle } = useAppActions();
  useEffect(() => {
    onSetTitle('Dashboard');
  }, [onSetTitle]);
  const latestMembership = data;
  const guide = data?.guide;

  if (loading) {
    return <FullCenteredSquiggleLoader data-testid="dashboard" />;
  }

  if (error) {
    return <DashboardError />;
  }

  if (!latestMembership) {
    return (
      <Box data-testid="Dashboard.NoMemberships">
        <DashboardEmpty />
      </Box>
    );
  }

  return (
    <>
      <Flex
        data-testid="dashboard"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        gap={{ base: '500', lg: '500', md: '300' }}
        maxW="100%"
        py="300"
        w="container.xl"
      >
        <Flex
          flexDir="column"
          gap={{ base: '300', lg: '600' }}
          w={{ base: '100%', lg: '65%', md: '55%' }}
        >
          <DashboardHeroBox />
          <DashboardCapsuleCards />
          <DashboardRoadmap />
        </Flex>
        <Flex flexDir="column" gap="500" w={{ base: '100%', lg: '35%', md: '45%' }}>
          <ActivityCenter />
          <SessionsStoreAdBlock />
          <YourPartnerContainer />
          <YourGuide
            email={guide?.email}
            funFacts={guide?.funFacts}
            guideFullName={guide?.fullName}
            guideProfilePhoto={guide?.profilePhotoSignedCDN}
            shortBio={guide?.shortBio}
          />
        </Flex>
      </Flex>
    </>
  );
});

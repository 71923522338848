import type { MouseEventHandler } from 'react';

import type { IModalState } from '../state/adminSlice/adminSlice';
import { useModalActions } from '../state/adminSlice/externalActions/useModalActions';
import { useModalData } from '../state/adminSlice/selectors/useModalData';
import { useModalOpen } from '../state/adminSlice/selectors/useModalOpen';

import { useEvent } from './useEvent';

export const useModal = (modalName: keyof IModalState) => {
  const actions = useModalActions();

  const openData = useEvent((data?: Record<string, unknown>) => {
    actions.onOpen({ data, isOpen: true, name: modalName });
  });

  const onOpen: MouseEventHandler<any> = useEvent((e) => {
    e.preventDefault();
    actions.onOpen({ isOpen: true, name: modalName });
  });

  const onClose = useEvent(() => {
    actions.onClose(modalName);
  });

  const isOpen = useModalOpen(modalName);
  const modalData = useModalData(modalName);

  return { isOpen, modalData, onClose, onOpen, openData };
};

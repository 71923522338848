import type { Maybe } from '@ours/types';
import { useMemo } from 'react';

import { useToVariableString } from './useToVariableString';

export const useVariableStrings = (str: Maybe<string>): string => {
  const toVarString = useToVariableString();

  return useMemo(() => toVarString(str), [toVarString, str]);
};

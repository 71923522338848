import type { Maybe } from '@ours/types';
import type { SESSION_CARD_DECK_DOWNLOAD } from '@ours/utils';
import { wait } from '@ours/utils';

import { useAnalyticsEvent } from './analytics/useAnalyticsEvent';
import { useAsyncEvent } from './useAsyncEvent';

export const useDownloadCardDeck = (
  location: SESSION_CARD_DECK_DOWNLOAD['payload']['location'],
  session: Maybe<{ cardDeckSignedUrl: Maybe<string>; id: string; title: Maybe<string> }>
) => {
  const { trackEvent } = useAnalyticsEvent();
  return useAsyncEvent(async () => {
    const download = async () => {
      await trackEvent({
        payload: { location, sessionId: session?.id || '' },
        type: 'SESSION_CARD_DECK_DOWNLOAD',
      });

      if (!session?.cardDeckSignedUrl) {
        return;
      }
      window.open(session?.cardDeckSignedUrl);
    };
    await Promise.all([download(), wait(2000)]);
  });
};

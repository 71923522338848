/* eslint-disable max-lines-per-function */
import type { Maybe } from '@ours/types';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { SimpleModal } from '../../../components/Modals/SimpleModal';
import { useDayjs } from '../../../hooks/date/useDayjs';
import { useTimeRelativity } from '../../../hooks/date/useTimeRelativity';
import { BoxComponent } from '../BoxComponent';
import { DASHBOARD_HERO_BOX } from '../content';

import { LiveHeroFooter } from './lib/LiveHeroFooter';
import { ReschedulingDisabled } from './lib/ReschedulingDisabled';

interface Props {
  endTime: Maybe<string>;
  guideName: Maybe<string>;
  isOpen: boolean;
  joinUrl: Maybe<string>;
  onClose: () => void;
  onJoinClick: () => void;
  onReschedule: () => void;
  startTime: Maybe<string>;
  title: Maybe<string>;
}

export const LiveEvent: FC<Props> = memo(
  ({
    endTime,
    guideName,
    isOpen,
    joinUrl,
    onClose,
    onJoinClick,
    onReschedule,
    startTime,
    title,
  }) => {
    const hourMinutes = useDayjs(startTime, { fallbackText: 'Coming soon...', format: 'HoursMin' });
    const dayDate = useDayjs(startTime, { fallbackText: 'Coming soon...', format: 'PrintedDay' });
    const { isAfterWithinHr, isFuture } = useTimeRelativity(startTime);
    const Footer = useCallback(
      // eslint-disable-next-line react-memo/require-memo
      () => (
        <LiveHeroFooter
          _onReschedule={onReschedule}
          endTime={endTime}
          joinUrl={joinUrl}
          startTime={startTime}
          title={title}
        />
      ),
      [onReschedule, endTime, joinUrl, startTime, title]
    );

    return (
      <>
        <BoxComponent
          CTAButton={() =>
            joinUrl ? (
              <Button
                as="button"
                isDisabled={!isAfterWithinHr}
                onClick={onJoinClick}
                size="roundXl"
                testId="LiveHeroEvent.joinButton"
                type="button"
              >
                {isFuture ? 'Getting Ready' : 'Click to Join'}
              </Button>
            ) : null
          }
          Footer={Footer}
          description={`${title} ${guideName ? `• With ${guideName}` : ''}`}
          tagline={DASHBOARD_HERO_BOX.ITS_LIVE_TAG_LINE}
          taglineColor="brand.yellow"
          titleLn1={hourMinutes}
          titleLn2={dayDate}
        />
        <SimpleModal isOpen={isOpen} onClose={onClose}>
          <ReschedulingDisabled />
        </SimpleModal>
      </>
    );
  }
);

import type { FC } from 'react';
import { memo } from 'react';

export const Night: FC = memo(() => {
  return (
    <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.8126 4C33.797 7.97766 34.5227 12.4654 33.8931 16.8657C33.2636 21.2661 31.3086 25.3703 28.2885 28.632C25.2685 31.8937 21.3265 34.1581 16.9875 35.1238C12.6485 36.0895 8.11833 35.7106 4 34.0376C7.89377 40.8157 13.1061 45.3893 21.2232 45.9456C33.2547 46.7696 44.627 38.158 45.2863 25.5702C45.5047 21.0305 44.3315 16.5322 41.9237 12.6774C39.5158 8.82264 35.9881 5.79513 31.8126 4Z"
        fill="#FFAB00"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});

import type { AvatarProps } from '@chakra-ui/react';
import { Avatar as ChakraAvatar, Skeleton } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import { uuid, wait } from '@ours/utils';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

interface IAvatarProps {
  alt?: string;
  border?: AvatarProps['border'];
  h: AvatarProps['h'];
  loading?: boolean;
  name: AvatarProps['name'] | null | undefined;
  src?: Maybe<string>;
  w: AvatarProps['w'];
}

export const Avatar: FC<IAvatarProps> = memo(({ loading, name, ...props }) => {
  const [retryUUID, setRetryUUID] = useState('');

  const onError = useCallback(async () => {
    if (props.src) {
      await wait(2000);
      setRetryUUID(uuid());
    }
  }, [props.src]);

  useEffect(() => {
    setRetryUUID('');
  }, [props.src]);

  const src = useMemo(() => {
    if (!retryUUID) {
      return props.src;
    }

    return props.src + '#' + retryUUID;
  }, [props.src, retryUUID]);

  if (loading) {
    return <Skeleton {...props} />;
  }

  return <ChakraAvatar {...props} name={name || ''} onError={onError} src={src || undefined} />;
});

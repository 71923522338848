import { Box, Flex } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useTimeOfDayGreeting } from '../../../hooks/date/useTimeOfDayGreeting';

interface Props {
  combinedStudentsName: Maybe<string>;
}

const NAME_REPLACE = 'NAME_REPLACE';
const lookup = {
  name: {
    Afternoon: `Happy afternoon, ${NAME_REPLACE}!`,
    Evening: `Good evening, ${NAME_REPLACE}!`,
    Morning: `Good morning, ${NAME_REPLACE}!`,
  },
  noName: {
    Afternoon: 'Happy afternoon, you!',
    Evening: 'Good evening, friend!',
    Morning: 'Good morning, sunshine!',
  },
} as const;

export const GreetingStatement: FC<Props> = memo(({ combinedStudentsName }) => {
  const statement = useTimeOfDayGreeting();
  const greeting = useMemo(() => {
    if (combinedStudentsName) {
      return lookup.name[statement].replace(NAME_REPLACE, combinedStudentsName);
    }
    return lookup.noName[statement];
  }, [combinedStudentsName, statement]);

  return (
    <Flex flexDir="column" gap={{ base: '50', md: '100' }} w={{ base: '100%', md: 'auto' }}>
      <Box data-testid="greeting.statement.name" textStyle={{ base: 'xl', md: '2xl' }}>
        {greeting}
      </Box>
      <Box textStyle={{ base: 'sm', md: 'md' }}>
        Welcome to your OURS dashboard! We're so glad you're here.
      </Box>
    </Flex>
  );
});

import { getItemAtRandom } from '@ours/utils';
import { useEffect, useState } from 'react';

import { useEvent } from './useEvent';

export const useRandomItem = <T>(items: T[]): [T | null, () => T] => {
  const [state, setState] = useState<T | null>(null);
  const next: () => T = useEvent(() => {
    const n = getItemAtRandom(items);
    if (n === state) {
      return next();
    }
    setState(n);
    return n;
  });
  useEffect(() => {
    setState(getItemAtRandom(items));
  }, [items]);

  return [state, next];
};

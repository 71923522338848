import type { FC } from 'react';
import { memo } from 'react';

export const CameraIcon: FC = memo(() => {
  return (
    <svg fill="none" height="31" viewBox="0 0 35 31" width="35" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 30H3C1.89543 30 1 29.1046 1 28V11C1 9.89543 1.89543 9 3 9H7.8C8.90457 9 9.8 8.10457 9.8 7V5C9.8 3.89543 10.6954 3 11.8 3H24.3C25.4046 3 26.3 3.89543 26.3 5V7C26.3 8.10457 27.1954 9 28.3 9H32C33.1046 9 34 9.89543 34 11V28C34 29.1046 33.1046 30 32 30Z"
        fill="#FFA6E3"
        stroke="black"
        strokeWidth="2"
      />
      <circle cx="18" cy="18" r="6" stroke="black" strokeWidth="2" />
    </svg>
  );
});

import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import { TimeConstants } from './timeConstants';

export const timeRelativity = (dateOrTime: string | number | Date | Dayjs | undefined | null) => {
  const now = dayjs();
  const dateAsDayjs = dayjs(dateOrTime);
  const isFuture = dateAsDayjs.isAfter(now);
  const isPast = dateAsDayjs.isBefore(now);
  const isAfterWithinHr = isPast && dateAsDayjs.add(1, 'h').valueOf() > now.valueOf();
  const diff = dateAsDayjs.valueOf() - now.valueOf();
  const isBeforeWithin24Hr = isFuture && diff < TimeConstants.MILLISECONDS_IN_HOUR * 24;
  return { isAfterWithinHr, isBeforeWithin24Hr, isFuture, isPast };
};

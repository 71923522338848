import { Colors, getItemAtRelIndex } from '@ours/utils';
import { Circle, Diagonal, Oval, RoundedRect, Square, Triangle } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {
  fill?: string;
  idx: number;
}
const shapeIcons = [Square, Triangle, RoundedRect, Diagonal, Circle, Oval];
const shapeFills = [
  Colors.brand.yellow,
  Colors.brand.blue,
  Colors.brand.pink,
  Colors.brand.orange,
  Colors.brand.red,
  Colors.brand.pink,
];

export const ShapeByIDX: FC<Props> = memo(({ fill: fillOverride, idx }) => {
  const ShapeIcon = getItemAtRelIndex(shapeIcons, idx);
  const fill = getItemAtRelIndex(shapeFills, idx);

  return ShapeIcon ? <ShapeIcon fill={fillOverride || fill} /> : null;
});

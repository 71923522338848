import { Box, Center } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { RedStartBurst } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

import { MarkDown } from '../../../components/Text/MarkDown';
import type { ScheduleLiveSessionProps } from '../../ScheduleLiveSession/ScheduleLiveSession';
import { ScheduleLiveSession } from '../../ScheduleLiveSession/ScheduleLiveSession';
import { DASHBOARD_HERO_BOX } from '../content';

export interface Props extends Omit<ScheduleLiveSessionProps, 'ctaText'> {
  description: string;
  isFirst: boolean;
  title: string;
}

export const ScheduleLiveEvent: FC<Props> = memo(({ description, isFirst, title, ...props }) => {
  return (
    <Center
      bg="white"
      border={Border}
      borderBottomRightRadius={{ base: '600', md: '800' }}
      borderTopLeftRadius={{ base: '600', md: '800' }}
      flexDir="column"
      pos="relative"
      px={{ base: '300', md: '1000' }}
      py="500"
      textAlign="center"
    >
      <Box
        display={{ base: 'none', md: 'block' }}
        left={{ base: '1px', lg: '30px', md: '5px' }}
        pos="absolute"
        top={{ base: '1px', lg: '20px', md: '10px' }}
      >
        <RedStartBurst />
      </Box>
      <Box fontSize={{ base: '500', md: '700' }} pb="100">
        {title}
      </Box>
      <Box fontSize={{ base: '200', md: '300' }} fontWeight="600" pb="200">
        {isFirst ? DASHBOARD_HERO_BOX.FIRST_SUB_HEADING : DASHBOARD_HERO_BOX.SUBSEQUENT_SUB_HEADING}
      </Box>
      <Box fontSize={{ base: '200', md: '300' }} pb="200">
        <MarkDown value={description} />
      </Box>
      <Box>
        <ScheduleLiveSession
          ctaText={isFirst ? DASHBOARD_HERO_BOX.FIRST_CTA : DASHBOARD_HERO_BOX.SUBSEQUENT_CTA}
          {...props}
        />
      </Box>
    </Center>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const RedStartBurst: FC<Props> = memo(() => {
  return (
    <svg
      fill="none"
      height="110"
      viewBox="0 0 88 110"
      width="88"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58444 54.291L1.5847 54.291C18.155 51.602 27.8801 48.285 33.9974 40.3157C37.0408 36.3508 39.1385 31.3028 40.8 24.7745C42.0346 19.9237 43.0372 14.218 44.0005 7.48036C44.9646 14.2179 45.9676 19.9235 47.2024 24.7741C48.8643 31.3022 50.9618 36.3498 54.0048 40.3145C60.1213 48.2835 69.845 51.6005 86.4152 54.2909L86.4156 54.291C86.7987 54.3531 87 54.6311 87 55C87 55.3689 86.7987 55.6469 86.4156 55.709L86.4153 55.709C69.845 58.398 60.1199 61.715 54.0026 69.6843C50.9592 73.6492 48.8615 78.6972 47.1999 85.2255C45.9654 90.0763 44.9628 95.782 43.9995 102.52C43.0354 95.7821 42.0323 90.0765 40.7975 85.2259C39.1357 78.6978 37.0382 73.6502 33.9952 69.6855C27.8787 61.7165 18.155 58.3995 1.58479 55.7091L1.58444 55.709C1.20126 55.6469 1 55.3689 1 55C1 54.6311 1.20126 54.3531 1.58444 54.291Z"
        fill="#FF7760"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

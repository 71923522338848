import { Box, Flex } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import { Border } from '@ours/utils';
import type { FC, MutableRefObject } from 'react';
import { memo } from 'react';

import { NavBar } from '../../Navs/NavBar';

import { GreetingStatement } from './GreetingStatement';
import { WeatherStatement } from './WeatherStatement';

interface Props {
  combinedStudentsName: Maybe<string>;
  navButtonRef: MutableRefObject<null | HTMLButtonElement>;
  onOpen: () => void;
}

export const DashboardNavBar: FC<Props> = memo(({ combinedStudentsName, navButtonRef, onOpen }) => {
  return (
    <Box bg="white" border={Border} borderBottomLeftRadius="700">
      <Box px={{ base: '300', md: '600' }}>
        <NavBar navButtonRef={navButtonRef} onOpen={onOpen} />
      </Box>
      <Flex
        alignItems="center"
        flexWrap={{ base: 'wrap', md: 'nowrap' }}
        justifyContent={{ base: 'center', md: 'space-between' }}
        pb={{ base: '200', md: '500' }}
        pt={{ base: '200', md: '300' }}
        px={{ base: '300', md: '600' }}
      >
        <GreetingStatement combinedStudentsName={combinedStudentsName} />
        <WeatherStatement />
      </Flex>
    </Box>
  );
});

import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

export const RoundedRect: FC<{ fill?: string }> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="32" viewBox="0 0 42 32" width="42" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 16C1 7.71573 7.71573 1 16 1L41 1V31L16 31C7.71573 31 1 24.2843 1 16Z"
        fill={fill}
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

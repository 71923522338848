import { memo } from 'react';

import type { IconBaseProps } from '../IconType';
import { sizeVariants } from '../IconType';

export const ArrowLeft = memo((props: IconBaseProps) => {
  const computedSize = sizeVariants[props.size || 'base'];
  const computedColor = props.color || 'black';
  return (
    <svg
      fill="currentColor"
      height={computedSize}
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 17 24"
      width={computedSize}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.2648 12.09L14.9182 16.7433C15.6425 17.468 16.0494 18.4506 16.0494 19.4752C16.0494 20.4998 15.6425 21.4825 14.9182 22.2071C14.1935 22.9315 13.2109 23.3384 12.1863 23.3384C11.1617 23.3384 10.179 22.9315 9.45439 22.2071L4.80317 17.5538L1.71663 14.4673C1.40444 14.1551 1.1568 13.7845 0.987847 13.3766C0.818892 12.9687 0.731934 12.5315 0.731934 12.09C0.731934 11.6485 0.818892 11.2114 0.987847 10.8035C1.1568 10.3956 1.40444 10.025 1.71663 9.71281L4.80317 6.62458L11.2721 0.156025L16.7355 5.61938L10.2648 12.09Z"
        fill={computedColor}
      />
    </svg>
  );
});

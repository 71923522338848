import { Box, Collapse, Flex, Grid, useBoolean } from '@chakra-ui/react';
import type { BrandColors } from '@ours/utils';
import { Border, Colors, RoundedCorners, toClockTime } from '@ours/utils';
import { useMachine } from '@xstate/react';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../components/Buttons/Button';
import { CountdownCircle } from '../../components/CountdownCircle/CountdownCircle';
import { MarkDown } from '../../components/Text/MarkDown';
import { useAnalyticsEvent } from '../../hooks/analytics/useAnalyticsEvent';
import { useColorGradient } from '../../hooks/useColorGradient';
import { useEvent } from '../../hooks/useEvent';
import { journalMachine } from '../Journal/lib/journalMachine';
import { MusicPlayer } from '../MusicPlayer/MusicPlayer';

import { DiscussionPrompt } from './lib/DiscussionPrompt';

export interface DiscussionProps {
  buttonText: string | undefined | null;
  color: BrandColors;
  digDeeperPrompt: string | undefined;
  onNext?: () => void;
  prompt: string;
  timeInSec?: number;
}

export const Discussion: FC<DiscussionProps> = memo(
  ({ buttonText, color, digDeeperPrompt, onNext, prompt, timeInSec: initialTimeInSec = 120 }) => {
    const [machineState, sendMachineEvent] = useMachine(journalMachine, {
      context: { timeInSec: initialTimeInSec, timeRemainingInSec: initialTimeInSec },
    });

    const [isDiggingDeeper, { toggle }] = useBoolean(false);
    // @ts-expect-error START_ACTION
    const canStart = machineState.can('START_ACTION');
    const colorOptions = useColorGradient(color, 'pink', !canStart);
    const { trackEvent } = useAnalyticsEvent();
    const timeInSec = machineState.context.timeInSec;
    const timeRemainingInSec = machineState.context.timeRemainingInSec;
    const onStart = useEvent(() => {
      if (canStart) {
        trackEvent({ type: 'DISCUSSION_START' });
        sendMachineEvent({ timeInSec, type: 'START_ACTION' });
      }
    });

    const onNextLocal = useEvent(() => {
      onNext?.();
      sendMachineEvent('PAUSE_ACTION');
    });
    const onAddTime = useEvent(() => {
      sendMachineEvent('ADD_TIME');
    });

    const onSubtractTime = useEvent(() => {
      sendMachineEvent('SUBTRACT_TIME');
    });

    return (
      <Grid
        {...colorOptions}
        border={Border}
        flex="none"
        gridRowGap="300"
        gridTemplateAreas={'"prompt timer" "dig dig" "buttons buttons"'}
        gridTemplateColumns={'auto 130px'}
        maxW="container.xl"
        overflow="hidden"
        pl="600"
        pos="relative"
        pr="300"
        py="300"
        w="full"
        {...RoundedCorners.Bottom}
      >
        {canStart ? (
          <DiscussionPrompt
            onAddTime={onAddTime}
            onStart={onStart}
            onSubtractTime={onSubtractTime}
            timeInSec={timeInSec}
          />
        ) : null}
        <Box gridArea="prompt">
          <MarkDown value={prompt} />
        </Box>
        <Flex alignItems="center" flexDir="column" gap="200" gridArea="timer">
          <CountdownCircle
            durationInSec={timeInSec}
            elapsedTimeInSec={timeInSec - timeRemainingInSec}
            size={120}
            strokeColor={Colors.brand.red}
            strokeWidth={5}
          >
            <Box textStyle="sm">{toClockTime(timeRemainingInSec)}</Box>
          </CountdownCircle>
        </Flex>
        <Collapse animateOpacity in={!!(digDeeperPrompt && isDiggingDeeper)}>
          <Box gridArea="dig">
            <MarkDown value={digDeeperPrompt!} />
          </Box>
        </Collapse>
        <Flex alignItems="flex-end" gridArea="buttons" justifyContent="space-between">
          <Box display={{ base: 'none', md: 'block' }} flexGrow={1}>
            <MusicPlayer />
          </Box>
          <Flex gap="200">
            {digDeeperPrompt ? (
              <Box>
                <Button
                  as="button"
                  onClick={toggle}
                  size="sm"
                  type="button"
                  variant={onNext ? 'beige' : 'blue'}
                >
                  <Box>{isDiggingDeeper ? 'Hide Prompt' : 'Go Deeper'}</Box>
                </Button>
              </Box>
            ) : null}
            {onNext ? (
              <Box>
                <Button as="button" onClick={onNextLocal} size="sm" type="button">
                  <Box>{buttonText || "We're Done!"}</Box>
                </Button>
              </Box>
            ) : null}
          </Flex>
        </Flex>
      </Grid>
    );
  }
);

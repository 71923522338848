import { useBoolean } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useState } from 'react';

import { SimpleModal } from '../../components/Modals/SimpleModal';
import { useEvent } from '../../hooks/useEvent';
import { useSessionReschedule } from '../../hooks/useSessionReschedule';
import { useToArray } from '../../hooks/useToArray';
import { useDashboardContext } from '../DashboardContainer/DashboardContext';
import { useDashboardListItems } from '../DashboardContainer/lib/useDashboardListItems';
import { ReschedulingDisabled } from '../DashboardHeroBox/LiveEvent/lib/ReschedulingDisabled';
import { MembershipSessionScheduler } from '../MembershipSessionScheduler/MembershipSessionScheduler';

import { MiniRoadmapView } from './MiniRoadmapView';

interface Props {}

export const MiniRoadmapViewContainer: FC<Props> = memo(() => {
  const [sessionId, setSessionId] = useState('');
  const [isOpen, isOpenCtl] = useBoolean(false);
  const { data } = useDashboardContext();
  const events = useToArray(data?.scheduledEvents);
  const listItemData = useDashboardListItems();
  const guideName = data?.guide?.fullName || '';
  const { isRescheduleOpen, offReschedule, onStartReschedule } = useSessionReschedule(isOpenCtl.on);
  const membershipId = data?.id || '';
  const onStartRescheduleLocal = useEvent((_sessionId: string) => {
    const event = events.find(({ sessionId }) => sessionId === _sessionId);
    setSessionId(_sessionId);
    onStartReschedule({ startTime: event?.startTime });
  });

  return (
    <>
      <MembershipSessionScheduler
        isOpen={isRescheduleOpen}
        isThrottled
        membershipId={membershipId}
        onClose={offReschedule}
        sessionId={sessionId}
      />
      <MiniRoadmapView
        guideName={guideName}
        listItemData={listItemData}
        membershipId={membershipId}
        onStartReschedule={onStartRescheduleLocal}
      />
      <SimpleModal isOpen={isOpen} onClose={isOpenCtl.off}>
        <ReschedulingDisabled />
      </SimpleModal>
    </>
  );
});

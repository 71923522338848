import { useCallback } from 'react';

import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';
import type { IModalState } from '../adminSlice';

export const useModalData = (id: keyof IModalState) => {
  const select = useCallback(
    (s: StoreState) => {
      return s.admin.modals.items[id].data;
    },
    [id]
  );

  return _useStore(select);
};

/* eslint-disable complexity */

import { useDashboardContext } from '../DashboardContext';

/**
 * There are 6 states for this component:
 *    - loading - The data is being fetched
 *    - onboardingSession - The user has an onboardingSessionId and has not completed it.
 *    - scheduledNoRoadmap - The first session is scheduled and there are no other sessions
 *    - miniRoadmap - There are other sessions
 *        This third state has it's own internal states.
 */
export const useDetermineDashboardRoadmapState = (): DashboardMiniRoadmapStates => {
  const { loading } = useDashboardContext();

  return loading ? 'loading' : 'miniRoadmap';
};

export type DashboardMiniRoadmapStates = 'loading' | 'miniRoadmap';

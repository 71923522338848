import { Box } from '@chakra-ui/react';
import { LINKS } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { Button } from '../../../components/Buttons/Button';

interface Props {}

export const EmptyCTAFooter: FC<Props> = memo(() => {
  return (
    <Box>
      <Box>
        <Button as="a" href={LINKS.PURCHASE_LINK} target="_blank">
          Purchase OURS
        </Button>
      </Box>
    </Box>
  );
});

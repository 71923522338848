import { toArray } from '@ours/utils';
import { useMemo } from 'react';

export function useToArray<T, CB extends (item: T, idx: number) => any>(
  items: null | undefined | readonly T[],
  cb: CB
): readonly ReturnType<CB>[];
export function useToArray<T>(items: null | undefined | readonly T[]): readonly T[];

export function useToArray<T, CB extends (item: T, idx: number) => any>(
  items?: null | undefined | readonly T[],
  cb?: CB
): readonly any[] {
  return useMemo(() => {
    const arr = toArray(items);

    return cb ? arr.map((item, idx) => cb(item, idx)) : arr;
  }, [items, cb]);
}

import { Box } from '@chakra-ui/react';
import { isNotNil, toSessionCapsule } from '@ours/utils';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useArrayToMap } from '../../../hooks/useArrayToMap';
import type { ContentBox } from '../../ContentBoxSlider/ContentBoxSlider';
import { ContentBoxSlider } from '../../ContentBoxSlider/ContentBoxSlider';
import { useDashboardContext } from '../DashboardContext';

export interface DashboardCapsuleCardsProps {}

export const DashboardCapsuleCards: FC<DashboardCapsuleCardsProps> = memo(() => {
  const { data } = useDashboardContext();
  const sessionsById = useArrayToMap('id', data?.sessions);

  const boxes = useMemo<ContentBox[]>(() => {
    const completedSessions = (data?.sessionProgresses || [])
      .filter((p) => p.completed && p.sessionId !== data?.onboardingSessionId)
      .map((p) => sessionsById[p.sessionId])
      .filter(isNotNil)
      .filter((s) => s.type === 'MODULE' && s.answerMode !== 'SeparateAsync')
      .reverse();

    return completedSessions.map((session) => {
      return {
        description: session.sessionDescription || '',
        link: toSessionCapsule(session.id),
        title: session.title || '',
      };
    });
  }, [data, sessionsById]);

  if (boxes.length === 0) {
    return null;
  }

  return (
    <Box w="full">
      <ContentBoxSlider boxes={boxes} />
    </Box>
  );
});

import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';

import { useInterval } from '../useInterval';

export const useTimeOfDayGreeting = () => {
  const [forceRender, setRenderer] = useState(0);
  const formatted = useMemo(() => {
    const hour = dayjs().hour();

    if (hour < 11) {
      return 'Morning';
    } else if (hour < 16) {
      return 'Afternoon';
    }

    return 'Evening';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRender]);

  const rerender = useCallback(() => {
    setRenderer((i) => i + 1);
  }, []);

  useInterval(rerender, 10000, false);

  return formatted;
};

import { Box, Center, Flex, Text } from '@chakra-ui/react';
import type { Maybe } from '@ours/types';
import { Border, CoupleRoutes, getItemAtRandom } from '@ours/utils';
import { WhiteStarBurst } from '@ours/web-icons';
import type { FC } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Buttons/Button';

export interface YourGuideProps {
  email: Maybe<string>;
  funFacts: Maybe<readonly string[]>;
  guideFullName: Maybe<string>;
  guideProfilePhoto: Maybe<string>;
  shortBio: Maybe<string>;
}
export const DASHBOARD_GUIDE = {
  FUN_FACT: 'Fun fact:',
  HEADING: 'Meet your Guide',
  LEARN_MORE: 'Learn More',
  MESSAGE: 'Message your Guide',
  NO_GUIDE_NAME: 'Coming soon!',
  NO_GUIDE_SHORT_BIO: '',
};

export const YourGuide: FC<YourGuideProps> = memo(
  ({ email, funFacts, guideFullName, guideProfilePhoto, shortBio }) => {
    const funFact = useMemo(() => {
      return funFacts?.length ? getItemAtRandom(funFacts) : null;
    }, [funFacts]);
    const onClickMessage = useCallback(() => {
      window.open(`mailto:${email}`);
    }, [email]);

    return (
      <Flex
        bg="brand.yellow"
        border={Border}
        borderBottomRadius="800"
        data-testid="YourGuide"
        flexDir="column"
        gap="200"
        minH="300px"
        px={{ base: '300', md: '500' }}
        py={{ base: '300', md: '500' }}
      >
        <Center fontSize="700">{DASHBOARD_GUIDE.HEADING}</Center>
        <Center flexDir="column">
          <Box pos="relative">
            <Box left="-26px" pos="absolute" sx={{ svg: { w: '45px' } }} top="-10px" zIndex="1">
              <WhiteStarBurst />
            </Box>
            <Avatar h="100px" name="" src={guideProfilePhoto} w="100px" />
          </Box>
          <Box data-testid="YourGuide.name" fontSize="400" fontWeight="600" pt="200">
            {guideFullName || DASHBOARD_GUIDE.NO_GUIDE_NAME}
          </Box>
        </Center>
        <Box data-testid="your.guide.shortbio" fontSize="300">
          {shortBio || DASHBOARD_GUIDE.NO_GUIDE_SHORT_BIO}{' '}
          {shortBio ? (
            <Box
              _hover={{ textDecoration: 'underline' }}
              as="a"
              display="inline"
              fontWeight="600"
              href={CoupleRoutes.GuidePage}
              target="_blank"
            >
              {DASHBOARD_GUIDE.LEARN_MORE}
            </Box>
          ) : null}
        </Box>
        {funFact ? (
          <Box data-testid="your.guide.funFact" fontSize="300">
            <Text fontWeight="600"> {DASHBOARD_GUIDE.FUN_FACT}</Text> {funFact}
          </Box>
        ) : null}

        {email ? (
          <Center>
            <Button as="button" onClick={onClickMessage} type="button" w="full">
              {DASHBOARD_GUIDE.MESSAGE}
            </Button>
          </Center>
        ) : null}
      </Flex>
    );
  }
);

import { Box, Fade, Flex } from '@chakra-ui/react';
import { ArrowLeft, ArrowRight } from '@ours/web-icons';
import type { FC } from 'react';
import { memo, useEffect, useState } from 'react';
import type { Swiper as SwiperType } from 'swiper/types';

import { Button } from '../../Buttons/Button';
import { Pin } from '../../Pin';

export const NextAndPrevArrows: FC<{
  isHovering: boolean;
  isLooped: boolean;
  isOpaque?: boolean;
  swiper: SwiperType | null;
}> = memo(({ isHovering, isLooped, isOpaque, swiper }) => {
  const [isBeginning, setIsBeginning] = useState(swiper?.isBeginning);
  const [isEnd, setIsEnd] = useState(swiper?.isEnd);

  useEffect(() => {
    const onChange = (e: SwiperType) => {
      setIsBeginning(e.isBeginning);
      setIsEnd(e.isEnd);
    };

    setIsBeginning(swiper?.isBeginning);
    setIsEnd(swiper?.isEnd);

    swiper?.on('slideChange', onChange);
    swiper?.on('resize', onChange);
    return () => swiper?.off('slideChange', onChange);
  }, [swiper]);

  return (
    <Pin pointerEvents="none" zIndex={2}>
      <Flex alignItems="center" h="full" justifyContent="space-between" px="100" w="full">
        <Fade in={isHovering}>
          {isLooped === true || !isBeginning ? (
            <Box opacity={isOpaque ? '60%' : undefined} pointerEvents="auto">
              <Button
                as="button"
                onClick={() => swiper?.slidePrev()}
                size="roundMd"
                type="button"
                variant="beige"
              >
                <ArrowLeft />
              </Button>
            </Box>
          ) : null}
        </Fade>
        <Fade in={isHovering}>
          {isLooped === true || !isEnd ? (
            <Box opacity={isOpaque ? '60%' : undefined} pointerEvents="auto">
              <Button
                as="button"
                onClick={() => swiper?.slideNext()}
                size="roundMd"
                type="button"
                variant="beige"
              >
                <ArrowRight />
              </Button>
            </Box>
          ) : null}
        </Fade>
      </Flex>
    </Pin>
  );
});
NextAndPrevArrows.displayName = 'NextAndPrevArrows';

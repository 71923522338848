import type { FC } from 'react';
import { memo } from 'react';

export const Cloud: FC = memo(() => {
  return (
    <svg fill="none" height="50" viewBox="0 0 50 50" width="50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.1101 38H10.8437C2.479 38 -1.82516 27.5239 6.45831 22.5091C6.75866 20.3422 7.63701 18.2961 9.0011 16.5858C10.3652 14.8756 12.1647 13.5642 14.2105 12.7895C16.2564 12.0147 18.4732 11.8052 20.628 12.183C22.7827 12.5607 24.796 13.5118 26.4564 14.9362C28.6829 14.5229 30.9838 14.9308 32.9326 16.0843C34.8813 17.2379 36.3457 19.0589 37.0543 21.2097C38.2906 21.0338 39.5499 21.1144 40.7537 21.4463C41.9575 21.7783 43.08 22.3546 44.0514 23.1393C45.0228 23.924 45.8221 24.9004 46.3997 26.0075C46.9773 27.1146 47.3208 28.3288 47.4087 29.5744V38H38.1101Z"
        fill="#69A1F2"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});

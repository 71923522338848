import { Box, Progress } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  currentModuleProgress: number;
  listItemBgColor: string;
}

export const ListItemProgress: FC<Props> = memo(({ currentModuleProgress, listItemBgColor }) => {
  return (
    <Box display={{ base: 'none', md: 'block' }} w="120px">
      <Progress
        aria-label="Module progress"
        size="xs"
        value={currentModuleProgress}
        variant={listItemBgColor === 'white' ? 'beige' : undefined}
      />
    </Box>
  );
});

import { Box } from '@chakra-ui/react';
import type { TimeSlot } from '@ours/utils';
import { TimeConstants } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

import { SimpleModal } from '../../components/Modals/SimpleModal';
import { TimeSlotSelector } from '../../components/TimeSlotSelector/TimeSlotSelector';
import { useCreateMembershipEventMutation } from '../../generated/apollo-hooks';
import { useQueryMembershipSessionAvailabilityQuery } from '../../generated/custom-hooks';
import { useEvent } from '../../hooks/useEvent';
import { useOnError } from '../../hooks/useOnError';
import { useToArray } from '../../hooks/useToArray';

interface Props {
  isOpen: boolean;
  isThrottled: boolean;
  membershipId: string;
  onClose: () => void;
  onComplete?: () => Promise<void> | void;
  sessionId: string;
}

export const MembershipSessionScheduler: FC<Props> = memo(
  ({ isOpen, isThrottled, membershipId, onClose, onComplete, sessionId }) => {
    const { data, loading } = useQueryMembershipSessionAvailabilityQuery({
      fetchPolicy: 'network-only',
      pollInterval: TimeConstants.MILLISECONDS_IN_MINUTE * 2,
      skip: !isOpen || !sessionId || !membershipId,
      variables: { query: { isThrottled, membershipId, sessionId } },
    });

    const session = data?.queryMembershipSessionAvailability?.session;
    const timeSlots = useToArray(data?.queryMembershipSessionAvailability?.timeSlots);
    const onError = useOnError();
    const [createMembershipEvent] = useCreateMembershipEventMutation({ onError });
    const onConfirmSelection = useEvent(async (timeSlot: TimeSlot) => {
      await createMembershipEvent({
        variables: {
          input: {
            endTime: timeSlot.endDateTime,
            guideId: timeSlot.guideId,
            membershipId,
            sessionId,
            startTime: timeSlot.startDateTime,
          },
        },
      });
      await onComplete?.();
    });

    return (
      <SimpleModal isOpen={isOpen} onClose={onClose} size="4xl">
        <Box py={{ base: '200', md: '400' }}>
          <TimeSlotSelector
            description={session?.sessionDescription}
            durationInMinutes={45}
            loading={loading}
            onConfirmSelection={onConfirmSelection}
            timeSlots={timeSlots}
            title={session?.title || 'Live session'}
          />
        </Box>
      </SimpleModal>
    );
  }
);

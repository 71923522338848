import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { useInitializeCoupleAppLazyQuery } from '../../generated/custom-hooks';
import { DashboardHeroFull } from '../DashboardHeroFull/DashboardHeroFull';

import { EmptyBodyCTA } from './lib/EmptyBodyCTA';
import { EmptyCTAFooter } from './lib/EmptyCTAFooter';

interface Props {}

export const DashboardEmpty: FC<Props> = memo(() => {
  const [refetch] = useInitializeCoupleAppLazyQuery();
  useEffect(() => {
    refetch({ fetchPolicy: 'network-only' });
  }, [refetch]);

  return (
    <DashboardHeroFull
      Body={() => (
        <>
          <Box>
            We're happy that you're here, but we're having trouble finding an active experience
            connected to your email address. In order to get started with OURS, you need to purchase
            a path. Click the button below to get started.
          </Box>
          <Box>
            Already purchased and not seeing it here? Contact us for support and we'll get it sorted
            out.
          </Box>
        </>
      )}
      BodyCTA={EmptyBodyCTA}
      CTAFooter={EmptyCTAFooter}
      subtitle="Well this is a little bit awkward..."
      title="Hi there, new friend!"
    />
  );
});

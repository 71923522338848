import { Box, Flex } from '@chakra-ui/react';
import { COMPANY_MAIL_TO_LINK } from '@ours/utils';
import { EmailSending } from '@ours/web-icons';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { Button } from '../../../components/Buttons/Button';
import { useModal } from '../../../hooks/useModal';
import { DASHBOARD_PARTNER } from '../../YourPartner/content';

interface Props {
  onResend: () => void;
}

export const InvitePartnerModalSuccess: FC<Props> = memo(({ onResend }) => {
  const { onClose } = useModal('invitePartnerModal');
  const onContact = useCallback(() => {
    window.open(COMPANY_MAIL_TO_LINK);
  }, []);

  return (
    <Flex flexDir="column" px="400" py="600">
      <Box pb="150">
        <EmailSending />
      </Box>
      <Box maxW="400px" pb="100" textStyle={{ base: 'xl', md: '2xl' }}>
        {DASHBOARD_PARTNER.SUCCESS_HEADER}
      </Box>
      <Box maxW="400px" pb="200" textStyle={{ base: 'xs', md: 'sm' }}>
        {DASHBOARD_PARTNER.SUCCESS_SUBHEADER} try{' '}
        <Box as="button" fontWeight="600" onClick={onResend} textDecoration="underline">
          resending
        </Box>{' '}
        or{' '}
        <Box as="button" fontWeight="600" onClick={onContact} textDecoration="underline">
          contact us
        </Box>
        .
      </Box>
      <Box>
        <Button as="button" onClick={onClose} type="button">
          {DASHBOARD_PARTNER.SUCCESS_CTA}
        </Button>
      </Box>
    </Flex>
  );
});

import type { FC } from 'react';
import { memo } from 'react';

// eslint-disable-next-line max-lines-per-function
export const Snow: FC = memo(() => {
  return (
    <svg fill="none" height="52" viewBox="0 0 50 52" width="50" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.9883 51V1"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M32.9462 4.05078L24.9887 11.9862L17.0532 4.05078"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M17.0532 48.0787L24.9887 40.1211L32.9462 48.0787"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M46.651 38.5117L3.34863 13.5117"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M9.95756 8.14062L12.8532 18.9939L2 21.9116"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M40.1298 43.9273L37.2341 33.074L48.0874 30.1562"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M46.651 13.5117L3.34863 38.5117"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M2 30.1094L12.8532 33.005L9.95756 43.8583"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M48.0874 21.8466L37.2341 18.9509L40.1298 8.09766"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
});

import { Colors } from '@ours/utils';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {
  fill?: string;
}

export const Oval: FC<Props> = memo(({ fill = Colors.brand.beige }) => {
  return (
    <svg fill="none" height="50" viewBox="0 0 74 50" width="74" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M73 25C73 31.4714 69.1183 37.4469 62.6031 41.8491C56.0937 46.2473 47.0447 49 37 49C26.9553 49 17.9063 46.2473 11.3969 41.8491C4.88174 37.4469 1 31.4714 1 25C1 18.5286 4.88174 12.5531 11.3969 8.15092C17.9063 3.75271 26.9553 1 37 1C47.0447 1 56.0937 3.75271 62.6031 8.15092C69.1183 12.5531 73 18.5286 73 25Z"
        fill={fill}
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
});

/* eslint-disable max-lines-per-function */
import { Box, Flex } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { Calendar } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {
  CTAButton: FC;
  Footer?: FC;
  content?: string | undefined;
  content2?: string;
  description: string;
  tagline: string;
  taglineColor: string;
  titleLn1: string;
  titleLn2: string;
}

export const BoxComponent: FC<Props> = memo(
  ({
    CTAButton,
    Footer,
    content,
    content2,
    description,
    tagline,
    taglineColor,
    titleLn1,
    titleLn2,
  }) => {
    return (
      <Box bg="brand.orange" border={Border} borderBottomRightRadius="800" pos="relative" py="500">
        <Flex justifyContent="space-between" px={{ base: '200', md: '500' }}>
          <Box
            bg={taglineColor}
            border={Border}
            borderBottomRightRadius="700"
            borderTopLeftRadius="700"
            display="inline-block"
            fontSize="500"
            left={{ base: '-1px', md: '-2px' }}
            pos="absolute"
            px="500"
            py="100"
            top={{ base: '-46px', md: '-48px' }}
          >
            {tagline}
          </Box>
          <Flex gap="300">
            <Box display={{ base: 'none', md: 'block' }} sx={{ svg: { maxW: 'none' } }}>
              <Calendar />
            </Box>
            <Box>
              <Box pb="50" textStyle="xl">
                {titleLn1}
              </Box>
              <Box pb="100" textStyle="xl">
                {titleLn2}
              </Box>
              <Box fontWeight="600" pb="200" textStyle="xs">
                {description}
              </Box>
              {content ? (
                <Box display={{ base: 'none', md: 'block' }} textStyle="xs">
                  {content}
                </Box>
              ) : null}
              {content2 ? <Box fontSize="300">{content2}</Box> : null}
              <Box display={{ base: 'none', md: 'block' }}>{Footer ? <Footer /> : null}</Box>
            </Box>
          </Flex>
          <Box>
            <CTAButton />
          </Box>
        </Flex>
        {content ? (
          <Box
            display={{ base: 'block', md: 'none' }}
            fontSize="300"
            px={{ base: '200', md: '500' }}
          >
            {content}
          </Box>
        ) : null}
        <Box display={{ base: 'block', md: 'none' }} px={{ base: '200', md: '500' }}>
          {Footer ? <Footer /> : null}
        </Box>
      </Box>
    );
  }
);
